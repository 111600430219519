@font-face{
  font-family:arial;
  font-style:normal;
  font-weight:400;
  src:local('Arial'),
  url("./font.woff") format('woff')
}

.font-arial {
  font-family: "arial";
}