input, button, select, optgroup, textarea {
  margin: 0;
}

p, div, button, label, input, select, option, h3, h4, h5, h6, a {
  font-family: var(--custom-font-family, 'Heebo', sans-serif);
}

$maxWidthMobilePhonesPortrait: 480px;

@mixin menu-button($width) {
  width: $width;
  border-radius: 5px;
  padding: 10px 0;
  margin-bottom: 30px;
  color: #fff;
  font-size: 16px;
  display: block;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  right: 0;
  left: 0;
  text-decoration: none;
  background-color: var(--color-secondary);
  @content;

  &:hover {
    background-color: var(--color-primary);
  }

  i {
    margin-right: 5px;
  }
}

@mixin product-list($scroll-bar-bg, $is-menu: false) {
  .product-list {
    max-height: 510px;
    overflow: auto;
    margin-bottom: 20px;
    @include scrollbar($scroll-bar-bg);

    .product {
      cursor: pointer;
      margin-bottom: 15px;

      .SLP-product-wrap {
        height: 129px;
        width: 185px;
        margin: auto;
        border: solid 2px #fff;
        border-radius: 15px;

        .product-image {
          background-size: cover;
          height: calc(100% - 28px);
          border-radius: 13px 13px 0 0;
          margin: auto;
          width: fit-content;

          img {
            width: 100%;
            height: 100%;
            border-radius: 15px 15px 0 0;
          }
        }

        .SLP-priceWrap {
          overflow: hidden;
          color: white;
          height: 28px;
          width: 100%;
          padding: 3px 5px 0;
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          align-items: center;

          .product-name {


          }

          .product-price {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .current-price {

            }


            .old-price {
              color: var(--color-secondary);
              text-decoration: line-through;
              margin-left: 5px;
            }

          }
        }
      }
    }
  }
}

@mixin scrollbar($color) {
  &::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-secondary);
  }
}

@mixin buy-button {
  .buy {
    width: 100%;
    height: 55px;
    border-radius: 5px;
    background: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    box-shadow: 0 8px 15px rgba(49, 159, 216, 0.25);
    font-family: "Hind", sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;

    &:disabled {
      opacity: .75;
    }
  }
}

.hoverBtnStyle {
  color: white;
  background: var(--color-secondary);

  &:hover {
    background-color: white;
    color: var(--color-secondary);
  }
}

.copyright-container {
  font-family: "Hind", sans-serif;
  font-size: 20px;
  line-height: 16px;
  color: #727272;
  display: flex;
  align-items: center;

  &:hover {
    color: #727272;
    text-decoration: none;
  }

  img {
    margin-left: 10px;
    max-height: 30px;
    object-fit: contain;
    width: auto;
  }
}

.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgba(237, 217, 217, 0.19) !important;
}

#ecommerce-tour-root {
  font-family: var(--custom-font-family, 'Heebo', sans-serif);
  line-height: 21px;

  .game-book-button {
    top: 200px;
  }

  #bottom-bar {
    #reel-categories {
      left: 314px;
      position: absolute;
    }

    .more-bar {
      bottom: 60px;
      left: 24px;

      .fas.music-status {
        background: none;
        border: none;
      }

      .sound-volume {
        bottom: 2px;
        margin-left: 46px;
        //margin-bottom: 60px;
      }

      .action-icons.left.more {
        margin-bottom: 40px;
        bottom: 0;
      }

      .more-btn.btn {
        background: rgba(40, 40, 40, 0.4);

        &.active {
          background: var(--color-secondary);
        }
      }

      @media screen and (max-width: 768px) {
        &:not(.categoriesOn) {
          bottom: 90px;
        }
      }
    }

    .actions-bar {
      overflow: visible;
      width: auto;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-left: 25px;
      height: 57px;
      padding-top: 10px;

      //height: 63px;
      border: 2px solid white;
      border-bottom: 0;
      backdrop-filter: blur(7px);
      background: #0000001f;

      .main-icons {
        overflow: visible;
      }

      .actions-btn.btn {
        //margin-top: 25px;
        margin-right: 13px;
        height: 40px;
        width: 40px;
        background-color: rgba(40, 40, 40, 0.27);

        &.active {
          background-color: var(--color-secondary);
        }

        i {
          font-size: 18px;
        }

      }

      @media screen and (max-width: 768px) {
        &:not(.white) {
          bottom: 30px !important;
        }
      }

    }
  }

  #contact-pop {
    .square-button {
      background: var(--color-secondary);
      border: 1px solid var(--color-secondary);
    }
  }

  .contact-popup-content {
    @include scrollbar(white);
  }

  .popup .close:hover svg,
  .popup .close:hover i {
    color: var(--color-secondary);
  }

  .buttonsBg {
    &.open {
      right: 800px !important;
      @media screen and (max-width: 768px) {
        right: 85% !important;
      }
    }

    .buttonBg {
      &.white {
        backdrop-filter: blur(53px);
        border: solid 2px #fff;
        background-color: rgba(0, 0, 0, 0.6);
        border-right: none;

        i {
          color: var(--color-secondary);
        }
      }
    }

    &.left {
      left: 0;
      right: auto;
      top: 40%;
      margin: 0;
      width: 70px;

      .buttonBg {
        &:hover {
          text-decoration: none;

          i {
            color: white;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      &.left {
        display: none;
      }
    }
  }

  .buttonBg {
    &.active {
      background: var(--color-secondary);
    }

    height: 52px;
    width: 79px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);

    i {
      font-size: 22px;
    }

    &:hover {
      i {
        color: white;
      }
    }
  }

  .left {
    .buttonBg {
      width: 100%;
      background: var(--color-secondary);
      border-radius: 0 28px 28px 0;
      padding: 0 10px 0 0;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  nav#menu-container {
    max-height: 90%;
    z-index: 112;
    font-family: "Heebo", sans-serif;

    .closeNav {
      display: none;
    }

    .SLP-priceWrap {
      padding-top: 3px;

      .product-name {
        max-width: 65%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }


      .product-price {
        max-width: 35%;
        overflow: hidden;
        display: flex;

        .current-price {
          white-space: nowrap;
        }
      }
    }

    .menuFilterBlock {
      padding: 0 15px 15px;

      input {
        border-radius: 5px;
        border: solid 2px rgba(45, 37, 37, 0.27);
      }


      button {
        width: 100%;
        padding: 0;
        height: 40px;
        border-radius: 10px;
      }


      div.cancelBtnWrap {
        width: 126.66px;
        margin-top: 13px;
        //margin-top: 21px;
      }
    }

    &.rtl {
      width: 0;
    }

    &.open {
      width: 800px;
      @media screen and (max-width: 768px) {
        width: 85%;
      }
    }

    #menu {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-left: 0;
        padding-right: 40px;

        &.open {
          background: var(--color-secondary);
        }
      }

      .sublinks, .sub_sublinks {
        margin-left: 0;
      }

      border-left: 1px solid white;
      padding: 15px 0;
      margin: 0;
    }

    .menu-bottom {
      bottom: 0;
      flex: 1;
      margin: 0;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      padding-right: 13px;
      border-left: 1px solid white;
      display: flex;
      padding-bottom: 15px;

      .payment-button {
        margin-top: 0;
      }

      .contact {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &.white {
      h2 {
        font-family: "Heebo", sans-serif;
        border-bottom: 1px solid rgba(112, 112, 112, 0.2);
        margin-bottom: 15px;
        padding-bottom: 15px;
        margin-right: 40px;
      }

      -webkit-backdrop-filter: blur(53px);
      backdrop-filter: blur(53px);
      border: solid 2px #fff;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 25px 0 0 25px;
      border-right: none;

    }

    .menu-product-items {
      .input-group {
        border-left: 1px solid white;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 20px 23px 15px 15px;
        height: auto;
        margin: -20px 0 0;
      }
    }

    h2.shopTitle {
      border: 1px solid white;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: none;
      padding: 15px 0 15px 20px;
      margin-left: 0;
      margin-bottom: 0;
    }

    h2.menuTitle {
      border-left: 1px solid white;
      padding-left: 20px;
      padding-top: 15px;
      margin: 0;
      border-bottom: 0;
    }

    .lastMenu {
      overflow-y: auto;
      border-left: 1px solid white;
      margin: 0;
      min-height: 100px;
    }

    #menu {
      min-height: 100px;
    }

    .menuDashedLine {
      border-color: white;
      margin: -1px 15px 0 10px;
      border-top: 1px dashed white;
    }
  }

  .logo-wrap {
    position: absolute;
    z-index: 2;
    left: 20px;
    top: 20px;

    .header-logo {
      width: 100px;

      &.width {
        width: 200px;
      }

      @media screen and (max-width: 768px) {
        width: 70px;
        &.width {
          width: 140px;
        }
        top: 20px;
      }
    }

    .more-info {
      z-index: 2;
      position: relative;
      top: 15px;
      margin: 0 auto;
      width: 75px;
      background: var(--color-secondary);
      color: #fff;
      border-radius: 4px;
      padding: 0;
      line-height: 24px;
      text-align: center;
      font-size: 11px;
    }
  }

  #external-popup {
    z-index: 111;
  }

  nav {
    li {
      &:hover {
        color: var(--color-secondary) !important;
      }
    }
  }

  .popup-about {
    border-radius: 15px;
    background: rgba(41, 41, 41, 0.5);
    min-height: 300px;
    width: 500px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 104;
    right: 0;
    bottom: 0;
    margin: auto;
    max-height: 500px;
    height: fit-content;
    padding: 20px;

    @media screen and (max-width: 768px) {
      width: 80%;
    }

    .close {
      position: absolute;
      float: none;
      z-index: 3;
      top: 10px;
      right: 10px;
      padding: 0;
      opacity: 1;
      font-size: 14px;
      cursor: pointer;
      height: 15px;
      width: 15px;
      line-height: 14px;
      text-align: center;

      &:hover {
        svg,
        i {
          color: var(--color-secondary);
        }
      }

      svg,
      i {
        color: #F8F8F8;
        font-size: 16px;
        transition: all 0.3s ease;
      }
    }

    .square-button {
      margin-top: 20px;
      text-align: center;
    }

    iframe {
      height: 100% !important;
      width: 100% !important;
      position: relative;
      top: 0;
      left: 0;
      border-radius: 8px;
    }

    &.rtl {
      text-align: right;

      input {
        text-align: right;
      }
    }

    .about-logo {
      display: flex;
      justify-content: center;
      margin-top: 25px;
      align-items: center;

      img {
        height: 100px;
      }
    }

    .about-title {
      font-weight: normal;
      font-size: 24px;
      text-align: center;
      color: #fff;
      margin-top: 25px;
      padding: 0 15px;
    }

    .about-social-icons {
      display: flex;
      justify-content: space-evenly;
      width: 225px;
      margin: 25px auto 0;

      a {
        background: var(--color-primary);
        border-radius: 50%;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          text-decoration: none;
          color: var(--color-primary);
        }

        i {
          font-size: 24px;
          color: white;
        }
      }
    }
  }

  .ecommerce-bottom-bar {
    .hideActionBar {
      overflow: hidden;
      position: absolute;
      margin: -15px 0 0 -15px;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      cursor: pointer;

      i {
        padding: 6px;
        border-radius: 50%;
      }
    }

    .action-icons {
      .more-menu {
        bottom: 0;
        width: auto;
        display: flex;
        margin-left: 46px;
        margin-bottom: -46px;

        .data-balloon {
          float: left;
          margin-bottom: 5px;
        }
      }

      .action-icon {
        background-color: rgba(40, 40, 40, 0.4);

        i {
          font-weight: normal;
        }
      }

      .action-icon.active {
        background: var(--color-secondary);
      }

      .action-icon:hover {
        background: var(--color-secondary);
      }

    }

    .btn {
      &:hover,
      &.active {
        background: var(--color-secondary);
      }
    }

    #reel-categories {
      bottom: 5px;
    }

    #bottom-bar-help {
      z-index: 1;
    }

    @media screen and (max-width: $maxWidthMobilePhonesPortrait) and (orientation: portrait) {
      &#bottom-bar {

        &:not(.open) {
          .actions-bar {
            bottom: 30px !important;
          }
        }

        #bottom-bar-help {
          background: rgba(0, 0, 0, .4);
          z-index: 1;
        }

        #reel-categories {
          display: none;
        }

        .action-icons {
          display: flex;
          flex-direction: column;
          bottom: 110px;

          &.categoriesOn {
            bottom: 80px;
          }

          .data-balloon {
            margin-bottom: 5px;
          }
        }

        .actions-bar {
          bottom: 0px !important;
          max-width: initial;
          height: 70px;
          padding-top: 15px;
          border-radius: 0;
          margin-bottom: -1px;
          //margin-bottom: -11px; was strange behavior on mobile - portrait

          -webkit-backdrop-filter: blur(45px);
          backdrop-filter: blur(45px);
          border-style: solid;
          border-width: 2px;
          border-image-source: linear-gradient(
                          360deg, rgba(0, 0, 0, 0.29) 67%, #fff 25%);
          border-image-slice: 1;
          background-image: linear-gradient(
                          208deg, rgba(246, 237, 237, 0.3) 0%, rgba(196, 196, 196, 0) 100%);
          margin-left: -1px;
          width: calc(100% + 2px);

          &.white {
            margin-bottom: 0;
          }

          .ecommerce-line {
            display: none;
          }

          .main-icons {
            width: 100%;
            display: flex;
            justify-content: space-around;
            overflow: visible;
          }

          .hideActionBar {
            display: none;
          }
        }

        .more-bar {
          left: 0;
          bottom: 0;
          margin-bottom: 110px;

          &.categoriesOn {
            margin-bottom: 80px;
          }
        }

        &.open.ecommerce-bottom-bar {
          bottom: 165px;
        }

        #reel-bar {
          height: 165px;
        }
      }

    }

    @media screen and (max-height: 480px) and (orientation: landscape) {
      &#bottom-bar {
        &.open {
          bottom: 165px;

          .actions-bar {
            width: calc(65% - 156px);
          }
        }

        #reel-bar {
          height: 165px;
          //min-width: 390px;
        }

        #reel-categories {
          display: none;
        }

        .actions-bar {
          bottom: 0 !important;
          right: 0;
          left: auto;
          border-top-right-radius: 0;
          border-right: 0;
        }

        .ecommerce-line {
          display: none;
        }

        #bottom-bar-help {
          z-index: 1;
          left: 0;
          right: auto;
        }

        .action-icons.right {
          left: 79px;
          right: auto;
          bottom: 33px;
          position: fixed;
        }

        .action-icons.left.more {
          position: fixed;
          left: 550px;
          bottom: -47px;
          margin-bottom: 0;

          .more-menu {
            left: 12px;
            bottom: 74px;
            display: flex;
            //left: 242px;
            position: fixed;
          }

          .sound-volume {
            bottom: 0;
            transform: rotate(90deg);

            .sound-controls {
              transform: rotate(-90deg);
            }
          }
        }

        .more-bar {
          bottom: 33px;
          left: 10px;
          position: fixed;
        }

        .main-icons:not(.categoriesOn) .data-balloon:not(:nth-child(1)) {
          display: none;
        }
      }
      .main-icons.categoriesOn {
        position: relative !important;
        bottom: 0 !important;
      }
    }
  }

  #menu .mainLink.open > svg:last-child,
  #menu .mainLink.open > i:last-child {
    transform: rotate(0deg) !important;
  }

  .ecommerce-line {
    display: none;
  }

  .ecommerce-bottom-bar {
    .ecommerce-line {
      position: absolute;
      display: block;
      width: 100px;
      margin: 44px 0 0 0;
      border-bottom: 2px solid white;
      border-bottom-right-radius: 2px;
      z-index: 9;
      border-top: none;
      height: 0;
    }


    .ecommerce-left-line {
      width: 67px;
      margin-left: -73px;
    }

    .ecommerce-right-line {
      width: calc(100vw - 241px);
      margin-left: 278px;
    }
  }

  #reel-bar {
    overflow-y: hidden;
    backdrop-filter: blur(7px);
    background: #0000001f;
    z-index: 1;

    .cartItemProductTitle {
      align-items: center;
      border-top: 2px solid white;
      justify-content: space-between;
      color: white;
      display: flex;
      position: absolute;
      bottom: 0;
      padding: 2px 2px 0 5px;
      text-align: center;
      height: 28px;
      width: 100%;

      .text-right {
        display: flex;
        overflow: hidden;
        max-width: 50%;
        //width: 50%;

        .price {
          white-space: nowrap;
        }

        .comparePrice {
          margin-left: 5px;
          color: var(--color-secondary);
          text-decoration: line-through;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .text-left {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 50%;
        //width: 50%;
      }
    }

    .cartItemBackground {
      background-size: cover;
      height: calc(100% - 28px);
      border-radius: 13px 13px 0 0;
      background-repeat: no-repeat;
      background-position: center;
      aspect-ratio: 4 / 3;
      margin: auto;
    }

    .cartItemsWrapWithScroll, .customReel {
      margin-top: 5px;
      margin-left: 20px;
      height: auto;
      min-width: calc(99% - 284px);
      overflow: auto hidden;
      display: flex;
      flex-wrap: nowrap;

      &::-webkit-scrollbar {
        height: 7px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.4);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--color-secondary);
      }

      &.clearFlexScroll {
        width: auto !important;
        margin: 10px 10px 0 30px;

        .preview {
          margin-left: 0;
          margin-right: 30px;
        }
      }

      .previewItemLike {
        background: var(--color-secondary);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 5px;
        right: 5px;

        i {
          position: initial;
          filter: none;
        }
      }
    }

    .reels-block {
      margin-top: 10px;
    }

    .scroll-div {
      display: flex;
    }

    .preview:not(.mini-cart-total) {
      border-radius: 15px;
      margin-left: 0;
      margin-right: 30px;
      height: 129px;
      overflow: visible;
    }

    .preview {
      border: 2px solid white;
      background-size: cover;
      height: 133px;
      width: 185px;
      min-width: 185px;
    }

    .payNow-title-button {
      width: 40%;
    }

    .mini-cart-total {
      margin-left: 27px;
      max-width: 242px;
      min-width: 242px;

      line-height: 21px;
      height: 133px;
      flex-grow: 0;
      //padding: 11px 3px 4px;
      //padding-top: 8px;
      color: white;
      font-weight: normal;
      background: transparent;
      box-shadow: none;
      border: 2px solid white;
      font-size: 14px;
      margin-top: 15px;
      text-align: left;

      .mini-cart-items {
        margin-top: 11px;

        span {
          font-weight: bold;
        }
      }

      .mini-cart-total-price {
        margin-top: 17px;
        border-top: 1px dashed white;
        position: relative;
        display: flex;

        .totalPriceWrap {
          width: 60%;
          margin: 0;

          height: 40px;
          padding-left: 11px;

          align-items: center;
          display: flex;
        }

        .totalPrice {
          margin-left: 5px;
          display: inline-block;
        }


        button {
          width: 100%;
          height: 40px;

          &:active, &:focus {
            outline: none;
          }
        }
      }

      .mini-cart-items, .mini-cart-sub-total {
        margin-left: 11px;
        display: flex;
      }

      .quantity-title, .subtotal-title, {
        white-space: nowrap;
        width: 116px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .mini-cart-price {
      height: 20px;
      width: 40px;
      text-align: center;
      font-size: 14px;
      color: #f8f8f8;
      border-radius: 10px;
      background: var(--color-secondary);
      margin: 5px 5px 0;
      line-height: 20px;
    }

    .cartItemsWrapWithScroll {
      margin-top: 11px;
      width: 100%;

      .vertical-price-quantity-box {
        .quantity-input {
          text-align: center;
          width: 100%;
          margin-left: -1px;
        }
      }
    }

    .vertical-price-quantity-box {
      display: flex !important;
      flex-direction: column;
      top: 5px;
      right: 0;
      left: auto !important;
      z-index: 999 !important;
      height: 69px;
      padding-left: 1px;
      padding-top: 1px;
      background: var(--color-secondary);
      margin-right: -16px;
      margin-top: 9px;
      width: 30px !important;
      border-radius: 6px;
      @media screen and (max-width: 768px) {
        width: 29px !important;
      }

      .quantity-input {
        height: 22px;
        background: none;
        font-size: 16px;
        color: white;
        margin: 0;
        text-align: left;
        padding: 0 0 0 1px;
        border-radius: 2px;
      }

      .quantity-button {
        height: 23px;
        border-radius: 50%;
        background: transparent;
        color: white;
        font-weight: bold;
        line-height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:focus {
          outline: none;
        }
      }
    }

    @media screen and (max-width: $maxWidthMobilePhonesPortrait) and (orientation: portrait) {
      .mini-cart-total-wrap-mobile-horizontal {
        //width: 144px; was too thin on mobile
        display: flex;
        min-width: 160px;
      }
      .cartItemsWrapWithScroll, .reels-block {
        margin-left: 5px;

        &.clearFlexScroll {
          margin-left: 5px;
        }
      }
      .preview {
        &:not(.mini-cart-total) {
          &:first-child {
            margin-left: 0;
          }

          min-width: 130px;
          max-width: 130px;

          .cartItemProductTitle {
            font-size: 13px;
          }
        }

        min-width: 100px;
        max-width: 100px;
      }
      .mini-cart-total {
        height: 149px;
        margin-left: 10px;
        min-width: 136px;
        margin-top: 5px;

        .quantity-title, .subtotal-title {
          width: 70px;
        }

        .mini-cart-total-price {
          flex-wrap: wrap;
          margin-top: 10px;

          .totalPriceWrap {
            width: 100%;
            padding: 0;
            justify-content: center;
            height: 30px;
          }
        }

        .payNow-title-button {
          width: 100%;
          height: 30px;

          & > button {
            height: 100%;
          }
        }
      }
    }

  }

  #menu-container {
    padding: 0;

    .menu-product-filter {
      max-height: calc(90vh - 4px);
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 20px 10px 0 20px;
      @media screen and (max-width: 768px) {
        //display: none;
        padding-left: 0;
      }

      .product-list {
        .product {
          max-width: 50%;

          .SLP-product-wrap {
            max-width: 100%;
          }
        }
      }
    }

    .menu-product-items {
      max-height: calc(90vh - 4px);
      padding: 20px 0 0 0;
      border-left: 1px solid rgba(41, 41, 41, 0.2);
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: auto;

      .input-serach-group {
        max-width: 100%;
      }

      .menu-link-text-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.rtl {
      .input-group {
        flex-direction: row-reverse;
        padding-left: 25px;
        padding-right: 20px;
      }
    }

    .input-group {
      font-family: "Heebo", sans-serif;
      margin-left: 25px;
      margin-bottom: 30px;
      padding-right: 40px;
      border-radius: 7px;
      height: 40px;
      width: 250px;

      //@media screen and (max-width: 768px) {
      //  width: calc(100% - 12px);
      //}

      input {
        height: 40px;
        border-radius: 7px 0 0 7px;
        background: rgba(41, 41, 41, 0.3);
        color: #F8F8F8;
        margin-top: 0;
        font-family: "Hind", sans-serif;

        &::placeholder {
          color: #F8F8F8;
          font-size: 16px;
        }
      }

      .input-group-text {
        background: var(--color-secondary);
        color: #fff;
        border: 0;
        border-radius: 0 7px 7px 0;
        width: 40px;
        justify-content: center;
        height: 40px;
      }
    }

    .payment-button {
      @include menu-button(200px) {
        margin: 30px auto 0;
      }
    }

    .filter-button {
      @include menu-button(130px);
      background: transparent;
      border-radius: 11px;
      border: solid 1px #fff;

      &.filterOpen {
        background-color: rgba(123, 111, 111, 0.27);
        color: rgba(255, 255, 255, 0.2);
      }
    }

    @include product-list(rgba(255, 255, 255, 0), true);
  }

  @media screen and (max-height: 480px) and (orientation: landscape) {
    nav#menu-container {
      max-height: 90%;
      max-width: 85%;
      overflow: hidden;

      &::-webkit-scrollbar {
        width: 7px;
      }

      .mf-clearButton {
        max-width: 50%;
        min-width: 50%;

        .cancelBtnWrap {
          min-width: 100%;
        }
      }

      .menu-product-filter {
        max-width: 66.65%;
      }

      .menu-product-items {
        max-width: 33.33%;
      }

      .container-fluid.product-list {
        height: auto;
        overflow: hidden scroll;
        margin-bottom: 0;
      }

      .filter-button {
        margin-left: 50px;
      }

      .closeNav {
        position: absolute;
        left: 15px;
        top: 15px;
        z-index: 1;
        width: 30px !important;
        height: 30px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 18px;
        font-weight: normal;
        background: white;
      }

      .menu-product-items {
        #menu::-webkit-scrollbar {
          width: 7px;
        }

        #menu_lastMenu.lastMenu {
          overflow-y: auto;
          border-left: 1px solid white;
        }

        .input-group {
          margin-left: 0;
          margin-top: -20px;
          padding-top: 17px;
        }
      }
    }

    .buttonsBg.white {
      .buttonBg {
        &.active {
          display: none;
        }

        &:not(.active) {
          height: 50px;
          width: 58px;

          &.white {
            display: none;
          }
        }

        i {
          font-size: 16px;
        }

      }
    }
  }

  @media screen and (max-width: $maxWidthMobilePhonesPortrait) and (orientation: portrait) {
    .buttonBg.white {
      display: none;
    }
    nav#menu-container {
      max-height: 85%;
      height: 85%;
      overflow-y: scroll;
      padding-top: 25px;

      .closeNav {
        border-radius: 50%;
        background: white;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px !important;
        height: 25px !important;
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .menuFilterBlock.scroll {
        .cancelBtnWrap {
          margin: 0 0 15px 0;
          width: 100%;
        }

        .minMaxButtonsWrap {
          margin-top: 0 !important;

          .minMaxFields {
            min-width: 100%;
            margin-bottom: 15px;
          }
        }

        .row {
          .col-4 {
            min-width: 100%;
            margin-bottom: 15px;
          }
        }
      }

      .menu-product-items {
        order: 0;

        .input-group {
          border: none;
        }
      }

      #menu {
        border: none;
      }

      #menu_lastMenu.lastMenu {
        border: none;
        margin: 10px 0 5px 0;
        overflow: auto;
        min-height: 38px;
      }

      h2.shopTitle, h2.menuTitle, .menu-bottom {
        border: none;
      }

      .menu-product-filter {
        display: block;
        order: 1;
        padding: 0;

        .product-list {
          height: auto;

          .product {
            max-width: 50%;

            .SLP-product-wrap {
              max-width: 100%;

              .SLP-priceWrap {
                flex-direction: column;
                height: auto;
                align-items: center;
              }

              .product-price {
                max-width: initial;
              }

              .product-image {
                height: calc(100% - 48px);
              }
            }
          }
        }

      }

      #landscape-menu-wrap {
        height: 100%;

        .menu-product-filter {
          order: initial;
          height: auto;
          overflow: scroll;
        }

        .menu-product-items {
          max-height: 100%;
          display: flex;
          flex-direction: column;
          padding-top: 0;

          h2.menuTitle {
            padding-bottom: 0;
          }
        }

        .category-rounded-list {
          padding: 15px;

          .category-rounded-filter {
            &.active {
              color: var(--color-secondary);
              border: 1px solid var(--color-secondary);
            }

            color: white;
            border: 1px solid white;
            display: inline-block;
            padding: 5px 15px;
            border-radius: 15px;
            margin: 4px 3px;
            font-weight: normal;
            font-size: 15px;
          }
        }

        .input-serach-group {
          margin: 0;
          width: 100%;
          padding: 15px;
        }

        .menuFilterBlock.scroll {
          .row .col-4 {
            max-width: 50%;
            min-width: 50%;
          }

          .minMaxButtonsWrap {
            .minMaxFields {
              margin-top: 55px;

              .filter-price {
                margin-top: -55px;

                .minFieldRow {
                  order: 1;
                  margin-top: -55px;
                }
              }
            }

            .mf-applyButton {
              max-width: 50%;
              min-width: 50%;
              margin-left: 50%;
              margin-top: -70px;

              .square-button {
                background: var(--color-secondary);
                color: white;
              }
            }
          }
        }

        .portrait-content-menu {
          padding: 15px;
          color: white;
          display: flex;

          .content-switch {
            &:not(.shop-switch) {
              &.active {
                border-bottom: 1px solid white;
              }

              text-align: center;
            }

            &.shop-switch {
              padding-left: 5px;
              text-transform: uppercase;
              color: var(--color-secondary);

              .clearFilter {
                border: 1px solid white;
                padding: 5px 15px;
                color: white;
                border-radius: 15px;
              }
            }

            i {
              margin-right: 5px;
            }

            width: 33.33%;
          }
        }

        .menu-bottom {
          padding-top: 15px;

          .payment-button {
            margin-top: 10px;
          }

          .contact {
            h5 {
              display: block;
            }

            padding-bottom: 0;
            margin-top: 5px;
          }
        }
      }
    }
  }

}

#filter-popup {
  line-height: 21px;
  max-height: 330px;
  max-width: 850px;
  width: 100%;
  padding: 30px;
  z-index: 111;
  background: white;

  .close {
    top: 30px;
    right: 30px;
    background: none;
    color: var(--color-primary);

    &:hover {
      color: var(--color-secondary);
    }
  }

  h2 {
    font-style: normal;
    padding: 0;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--color-primary);
    text-align: left;
  }

  .filter-price {
    input {
      background: #fff;
      border: 1px solid #efefef;

      ::placeholder {
        font-size: 16px;
        color: #282828;
        opacity: 0.5;
      }
    }
  }

  .square-button {
    background: var(--color-secondary);
    border-color: var(--color-secondary);
    color: #fff;
    border-radius: 4px;
    width: 120px;
    float: right;
    margin-top: 0;
    font-weight: normal;
    font-size: 16px;
    text-align: center;

    &.clear-form {
      background: var(--color-primary);
      border-color: var(--color-primary);
    }
  }
}

#cart-popup {
  line-height: 21px;
  max-width: 100%;
  font-family: "Heebo", sans-serif;
  background-color: rgba(0, 0, 0, 0.64);
  backdrop-filter: blur(53px);
  border: 4px solid white;
  border-radius: 18px;
  width: 941px;
  color: white;
  height: 80%;
  padding: 30px;
  max-height: initial;

  .mobile-price {
    display: none;
  }

  .scroll {
    padding-right: 15px;

    &::-webkit-scrollbar {
      width: 6px;
    }
  }

  .square-button {
    background: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    color: #ffffff;
    height: 40px;
    line-height: 20px;
    padding: 0;
    font-size: 16px;

    i {
      margin-right: 5px;
    }
  }

  .tab-section {
    margin-top: 35px;
  }


  .tab-title {
    font-size: 17px;
  }

  .price-quantity-box {
    margin-left: 15px;
    border: 1px solid white;
    border-radius: 10px;
    height: 26px;

    .quantity-button {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      min-width: 25px;
      color: white;
      background: transparent;
      line-height: 20px;

      &:focus {
        outline: none;
      }

    }


    .quantity-input {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      height: auto;
      width: 30px;
      border: 0;
      background: transparent !important;
      margin-top: 0;
    }
  }

  h2 {
    font-family: "Heebo", sans-serif;
  }

  .prod-cart-info {
  }

  .tab-section-title {
    background: transparent;
    color: white;

    &.active {
      background: transparent;
    }
  }

  .close {
    top: 21px;
    right: 21px;
    background: white;
    color: rgba(0, 0, 0, 0.64);
    opacity: 1;

    &:hover {
      color: var(--color-secondary);
    }
  }

  .scroll {
    margin-top: 31px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    padding-left: 25px;
  }

  h2 {
    font-style: inherit;
    font-weight: bold;
    font-size: 32px;
    line-height: 34px;
  }

  .product-block {
    margin-right: 15px;
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 10px 25px;


    .product-image {
      width: 167px;
      height: 94px;
      border-radius: 5px;
      border: 2px solid white;
      overflow: hidden;
      padding: 0;

      img {
        height: 100%;
        aspect-ratio: 4 / 3;
      }
    }

    .prod-img-wrap {
      width: 16.66%;
    }

    .prod-cart-info {
      width: 58.33%;
    }

    .prod-cart-price {
      width: 8.33%;
    }

    .product-title {
      font-size: 17px;
      text-align: left;
    }

    .product-qty {
      margin-top: 5px;
      font-size: 17px;
      display: flex;
      align-items: center;

      .status {
        font-size: 14px;
        line-height: 16px;
        color: #727272;
        margin-left: 30px;
        font-weight: normal;
      }

    }

    .product-options {

      .product-option {
        font-size: 17px;

        ul > li {
          margin-top: 5px;

          span {
          }
        }
      }
    }

    .price-block {
      border: 1px dotted white;
      width: 155px;
      height: auto;
      padding: 10px 17px;

      .price-block-title {
        font-size: 17px;
        line-height: 1;
        margin-bottom: 5px;
      }

      .price {
        font-weight: bold;
        font-size: 17px;
        line-height: 1;
      }

      .old-price {
        margin-bottom: 5px;

        .price {
          font-size: 15px;
          color: var(--color-secondary);
          text-decoration: line-through;
          line-height: 1;
        }

        .discount {
          margin-left: 10px;
          font-size: 15px;
          line-height: 1;
        }
      }
    }
  }

  .alert-danger {
    margin-right: 15px;
  }

  .line {
    border: 1px solid #e8e8e8;
    margin-right: 15px;
  }

  .input-c input {
    border: 1px solid #eee;
  }

  .summaryContentTable {
    color: black;

    .hidden {
      display: none !important;
    }

    .orderSummaryTable {
      color: #ffffffc7;
      width: 309px;
      height: 170px;
      box-shadow: 0 4px 6px 3px rgba(0, 0, 0, 0.25);
      border: solid 1px #ffffffc7;
      padding: 17px 18px;
      font-size: 17px;

      .shippingRow {
        margin-top: 9.6px;
      }

      .totalRow {
        color: white;
        margin-top: 17px;
        padding-top: 17px;
        border-top: 1px solid #ffffffc7;
      }
    }
  }

  .input-c input {
    margin-bottom: 15px;
    border: 1px solid #eee;
    border-radius: 5px;
  }

  .protection {
    .protection-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;

      i {
        color: var(--color-secondary);
        margin-right: 10px;
      }
    }

    .protection-info {
      font-size: 16px;
      line-height: 23px;
      margin-top: 15px;
      margin-left: 27px;
    }
  }

  .summary {
    height: 200px;
    max-width: 300px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 11px 50px rgba(0, 0, 0, 0.11);
    padding: 20px;
    width: 100%;

    .summary-title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.13em;
      line-height: 16px;
      text-align: center;
    }

    ul.summary-list {
      margin-top: 20px;

      li {
        font-size: 16px;
        line-height: 23px;
        margin-top: 10px;

        span {
          &:last-child {
            float: right;
          }
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          &:last-child {
            font-weight: bold;
            font-size: 24px;
            line-height: 35px;
          }
        }
      }
    }
  }

  @include buy-button;

  .arrowTimes {
    display: none;
  }

  @media screen and (max-width: $maxWidthMobilePhonesPortrait) and (orientation: portrait) {
    border: none;
    border-radius: 0;
    height: 100%;
    padding-right: 0;
    padding-top: 0;
    padding-left: 10px;

    .arrowTimes {
      display: block;
      font-size: 28px;
      height: 41px;
      margin-left: 14px;
      left: 0;
      position: absolute;
      margin-top: 2px;
    }

    .fa-times {
      display: block;
    }

    & > .scroll {
      padding-right: 10px;
      padding-left: 0;

      h2 {
        text-align: center;
        font-size: 28px;
      }

      .product-block {
        display: flex;
        flex-wrap: nowrap;
        padding: 0 0 10px 15px;
        overflow: hidden;

        .prod-cart-price {
          display: none;
        }

        .product-image {
          min-width: 128px;
        }

        .prod-cart-info {
          & > div {
            font-size: 15px;
          }

          .quantity-button {
            min-width: 17px;
          }

          .product-options {
            .product-option {
              min-width: 100%;
              font-size: 15px;

              ul {
                margin: 0;
              }
            }
          }

          .mobile-price {
            display: flex;
            margin-top: 5px;

            .price-title {
              margin-right: 10px;
            }
          }
        }
      }
    }
    #cart-state {
      margin-top: 15px;
    }
  }


  @media screen and (max-height: 480px) and (orientation: landscape) {
    & > .scroll {
      margin-top: 0;
    }
    .protection,
    .summaryContentTable {
      max-width: 50%;
    }

    .orderSummaryTable {
      max-width: 100%;
    }
  }

  //@media screen and (max-width: 768px) {
  //  .payment-method {
  //    margin: 0 auto;
  //  }
  //}
}

#e-commerce-product-popup {
  line-height: 21px;
  font-family: 'Heebo', sans-serif;
  background: rgba(0, 0, 0, 0.64);
  backdrop-filter: blur(53px);
  border: 4px solid white;
  border-radius: 18px;
  width: 860px;
  color: white;
  height: 80%;
  padding: 30px;
  max-height: initial;
  max-width: 100%;

  .fa-times {
    right: 21px;
    top: 21px;
    background: white;
    opacity: 1;
    color: black;
  }

  .close {
    &:hover {
      color: var(--color-secondary);
    }
  }

  .scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 15px;
    margin-top: 31px;

    @include scrollbar(white);

    .product-container {
      min-height: 100%;
      margin-bottom: -50px;

      .payment-button {
        background: #00b1ff;
        width: 250px;
        height: 50px;
        line-height: 50px;
        text-transform: uppercase;
        font-size: 15px;
      }
    }
  }

  h2 {
    font-weight: bold;
    font-size: 32px;
    line-height: 34px;
    font-style: initial;
  }

  .product-media {
    overflow: hidden;
    width: 389px;

    .slick-slider {
      .slick-slide > div > div {
        display: flex;
        justify-content: center;
        width: 100%;


        //display: inline-block;
        //width: 389px;

        img {
          height: 100%;
          margin: 0 0 0 0;
          object-fit: contain;
          max-width: none;
          max-height: none;
          aspect-ratio: 4 / 3;

        }

        height: 391px;
        margin: auto;
        overflow: hidden;
        border-radius: 12px;
      }
    }

    .product-image {
      max-height: 500px;
      max-width: 330px;
      margin: 0 auto;
      //@media screen and (max-width: 768px) {
      //  max-width: 100%;
      //}

      img {
        padding: 0 5px;
        max-height: 500px;
        max-width: 100%;
        width: auto !important;
        margin: 0 auto;
      }
    }

    .product-image-first {
      img {
        max-width: 330px !important;
      }
    }

    .slick-list {
      min-height: 290px;
      max-height: 500px;
    }

    div.hidden {
      //display: none!important;
      opacity: 0;
    }

    .previewWithLoader > div {
      position: absolute;
      //left: calc(50% - 40px);
      //top: calc(50% - 40px);
      top: 143px;
      left: 143px;
    }
  }

  .action-icon-buttons {
    margin-top: 2px;

    .button-icon {
      width: 30px;
      height: 30px;
      background-color: white;
      color: var(--color-primary);
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.statusButton {
        background: none;
        color: white;
        margin-right: 10px;

        i {
          font-size: 31px;

          &.fa-play-circle {
            font-weight: 400;
          }
        }

        &.active {
          i {
            &.fa-heart {
              font-weight: 900;
            }
          }
        }
      }

      &.hoverBtnStyle {
        &:hover {
          background-color: white;
          color: var(--color-secondary);
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  .sliderWrap {
    max-width: 50%;
    overflow: hidden;

    .product-media {
      max-width: 100%;
    }

    .slick-dots {
      z-index: 1;
      margin-left: 25%;
      width: 50%;
      bottom: -32px;
      overflow: hidden;
      height: 30px;

      li.slick-active {
        button {
          background: var(--color-secondary);
        }
      }

      button {
        &:before {
          display: none;
        }

        background: white;
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }

      @media screen and (max-width: $maxWidthMobilePhonesPortrait) and (orientation: portrait) {
        margin-left: 62px;
      }
    }
  }

  .descriptionWrap {
    max-width: 50%;
    padding-left: 55px;
    padding-right: 20px;
  }

  .description {

    h3 {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #727272;
      text-transform: uppercase;

      i {
        color: var(--color-secondary);
      }
    }

    .description-text {
      font-size: 16px;
      line-height: 23px;

      p {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.34px;
        text-align: left;
        color: #fff;
      }

      em {
        font-style: italic;
      }

      strong {
        font-weight: bold;
      }

      //@media screen and (max-width: 768px) {
      //  height: 80%;
      //  margin-left: 0;
      //}
      .description-dangerous {
        color: initial;

        s, strike {
          text-decoration: line-through;
        }

        cite, dfn, em, i, var {
          font-style: italic;
        }

        b, strong {
          font-weight: bold;
        }

        u, ins {
          text-decoration: underline;
        }

        ol, ul {
          padding-left: 30px;

          li {
            list-style: initial;
          }
        }
      }
    }
  }

  .file-list {
    .file {
      cursor: pointer;
      height: 38px;
      background: rgba(232, 232, 232, 0.2);
      border: 1px solid #e8e8e8;
      font-family: "Hind", sans-serif;
      font-size: 16px;
      line-height: 38px;
      color: white;
      flex-wrap: nowrap;

      .document-icon {
        margin-right: 15px;
        margin-left: 15px;
      }

      .doc-text-block {
        width: 100%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .download-icon {
        margin-right: 15px;
        margin-left: 15px;
      }

      i {
        color: var(--color-secondary);
      }
    }
  }

  .options {
    label {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.13em;
      line-height: 16px;
      color: #727272;
    }

    .basic-single {
      input {
        height: unset;
        margin: unset;
      }
    }
  }

  .small-description {
    font-size: 16px;
    line-height: 23px;
  }

  .productPriceTable {
    border: 1px solid white;

    & > div {
      padding: 14px 0 0 23px;
      display: flex;

      .productPriceTable-quantityWidget::selection, .productPriceTable-quantityWidget > span::selection {
        background: transparent;
      }

      .productPriceTable-quantityWidget {
        border: 1px solid white;
        border-radius: 10px;
        margin-left: 15px;

        span {
          padding: 0 10px;
          cursor: pointer;
        }

        span:nth-child(2) {
          padding: 0;
          cursor: initial;
        }
      }


      &.productPriceTable-totalWrap {
        border-top: 1px dashed;
        position: relative;
        padding-bottom: 9px;
        padding-top: 9px;
      }

      &.productPriceTable-subtotal {
        padding: 9px 0 9px 23px;

        .productPriceTable-subtotal-old {
          color: var(--color-secondary);
          margin-left: 5px;
          text-decoration: line-through;
        }
      }

      .productPriceTable-total {
        width: 50%;
      }

      .productPriceTable-addToCart {
        height: 100%;
        //height: 38px;
        margin-top: -9px;
        position: absolute;
        right: 0;
        width: calc(50% - 15px);
      }

      .disabled-btn {
        background-color: #605f5f;
        pointer-events: none;
      }

    }

    .PI-successful-purchase {
      padding: 8px;
      display: flex;
      justify-content: center;
      background: var(--color-secondary);
      align-items: center;
      cursor: pointer;
    }

    .PI-product-added, .PI-successful-purchase {
      i {
        margin-right: 7px;
      }
    }
  }

  .price-box {
    font-family: "Hind", sans-serif;
    min-height: 200px;
    max-height: 300px;
    border-radius: 5px;
    background: rgba(0, 177, 255, 0.05);
    border: 1px dashed #00b1ff;
    padding: 15px;

    //@media screen and (max-width: 768px) {
    //  max-height: 400px;
    //}

    .original-price {
      font-size: 20px;
      line-height: 35px;
      color: #727272;

      span {
        font-weight: bold;
        color: var(--color-secondary);
      }
    }

    .our-price {
      font-size: 20px;
      line-height: 35px;
      color: var(--color-secondary);
      font-weight: bold;

      span {
        font-weight: bold;
        color: #00b1ff;
      }
    }

    .quantity {
      font-size: 20px;
      line-height: 35px;
      color: #727272;
      margin-bottom: 15px;

      .out-off-stock {
        font-weight: bold;
        color: var(--color-secondary);
      }
    }

    @include buy-button;

    //@media screen and (max-width: 768px) {
    //  .quantity {
    //    flex-direction: column;
    //  }
    //}
  }

  .message {
    font-weight: bold;
    color: #00b1ff;
    font-size: 20px;
    line-height: 35px;

    &.error {
      color: var(--color-secondary);
    }
  }

  .favorite {
    margin-top: 25px;
    width: 100%;
    border-radius: 5px;
    background: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    color: #fff;
    font-size: 16px;
    font-family: "Hind", sans-serif;
    font-weight: normal;
    height: 45px;

    i {
      margin-right: 5px;
    }
  }

  .social {
    margin-top: 25px;

    .social-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .social-icons {
      a {
        width: 26px;
        height: 26px;
        color: white;
        font-size: 22px;
        margin-right: 10px;

        &:hover {
          color: var(--color-secondary);
        }
      }
    }
  }

  .line {
    border: 1px solid #e8e8e8;
    margin: 25px 0;
  }

  .specification {
    h3 {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #727272;
      text-transform: uppercase;
      margin-bottom: 10px;

      i {
        color: var(--color-secondary);
        margin-right: 10px;
      }
    }

    .items {
      .item {
        font-size: 16px;
        line-height: 23px;
        color: white;

        span {
          font-weight: bold;
        }
      }
    }
  }

  .similar {
    h3 {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #727272;
      text-transform: uppercase;
      margin-bottom: 10px;

      i {
        color: var(--color-secondary);
        margin-right: 10px;
      }
    }

    h5 {
      background: #5f9ea0;
      color: #fff;
      font-size: 36px;
      line-height: 100px;
      margin: 10px;
      padding: 2%;
      position: relative;
      text-align: center;
    }
  }

  .similar {
    .slick-arrow {
      i {
        color: #D9EAEC;
        font-size: 20px;
      }
    }

    .slick-next {
      right: -15px;
    }

    .slick-prev {
      left: -15px;
    }

    .slick-prev:before, .slick-next:before {
      content: "";
    }

    .product-item {
      padding-right: 12px;
      padding-left: 12px;
      cursor: pointer;

      .product-image {
        position: relative;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        .product-prices {
          position: absolute;
          bottom: 10px;
          right: 10px;
          border-radius: 17px;
          background: #fff;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 10px 2px;

          .old-price {
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            color: var(--color-secondary);
            text-decoration: line-through;
            margin-right: 5px;
          }

          .price {
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
            text-align: left;
            color: #00b1ff;
          }
        }
      }

      .product-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #2c2c2c;
        margin-top: 10px;
        margin-bottom: 5px;
        height: 23px;
        overflow: hidden;
      }

      .product-description {
        font-size: 16px;
        line-height: 20px;
        color: #2c2c2c;
      }
    }
  }

  &.popup iframe {
    position: relative !important;
    min-height: 290px;
    max-height: 500px;
  }

  .mobileArrowWrap {
    display: none;
  }

  .hideText {
    max-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .readMore {
    cursor: pointer;
    margin-top: 8px;
    font-size: 17px;
    font-weight: bold;
    text-decoration: underline;
  }

  @media screen and (max-width: $maxWidthMobilePhonesPortrait) and (orientation: portrait) {
    height: 100%;
    width: 100%;
    border-radius: 0;
    border: 0;
    padding-right: 0;

    .hexa3dActive {
      & > .slick-slider.slick-initialized {
        height: 350px;

        & > .slick-list {
          height: 350px;

          & > .slick-track {
            height: 350px;

            & > .slick-slide {
              height: 350px;

              & > div {
                height: 350px;

                & > div {
                  height: 350px;
                }
              }
            }
          }
        }
      }

      &.popup iframe {
        height: 350px;
      }

    }

    .product-media {
      max-width: 100%;

      .slick-list {
        //height: 300px;
        .slick-track {
          & > div {
            height: 300px;

            & > div {
              height: 300px;

              & > div {
                height: 300px;
              }
            }

          }

          height: 300px;
        }
      }
    }

    .descriptionWrap {
      padding: 0;
      max-width: inherit;

      & > h2 {
        display: none;
      }
    }
    .sliderWrap {
      max-width: inherit;
    }

    & > .scroll {
      margin-top: 0;
      padding-right: 30px;

      .product-container {
        .container-fluid:nth-child(2) {
          padding: 0;
        }
      }
    }
    span.fa-times {
      display: none;
    }
    .mobileArrowWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 30px;

      h2 {
        margin-left: 30px;
        font-size: 30px;
      }

      i.arrowTimes {
        font-size: 33px;
        height: 41px;
        margin-left: 14px;
        left: 0;
        position: absolute;
      }
    }
  }

  //@media screen and (max-width: 768px) {
  //  height: 80%;
  //  width: 90%;
  //
  //  .d-none-mobile {
  //    display: none !important;
  //  }
  //}
}

#e-commerce-tag-products-popup {
  line-height: 21px;
  padding: 20px 10px 0;
  width: 544px;
  height: 461px;
  -webkit-backdrop-filter: blur(53px);
  backdrop-filter: blur(53px);
  border: solid 5px #fff;
  background-color: rgba(0, 0, 0, 0.64);
  border-radius: 25px;

  h2 {
    color: white;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-family: 'Heebo', sans-serif;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .SLP-priceWrap {
    overflow: hidden;

    .product-name {
      min-width: 50%;
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .product-price {
      min-width: 50%;
      text-align: end;

      .current-price {
        font-size: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .old-price {
        font-size: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 85%;
  }

  .close {
    right: -15px;
    top: -15px;

    &:hover {
      color: var(--color-secondary);
    }
  }

  @include product-list(rgba(0, 0, 0, 0));

  .scroll {
    max-height: calc(100% - 56px);
    overflow: hidden auto;
  }

  .product-list {
    padding-bottom: 0;
    overflow: visible;

    h2 {
      text-align: center;
      flex-grow: 0;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.6px;
      color: #fff;
      margin-bottom: 20px;
    }

    .row {
    }

    .product {

      .product-name, .product-price {
        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: $maxWidthMobilePhonesPortrait) and (orientation: portrait) {
    overflow: hidden;
    .menu-bottom .payment-button {
      margin-left: 33px;
    }
    h2 {
      font-size: 23px;
      font-weight: normal;
      margin-right: 38px;
      padding-right: 34px;
      padding-left: 15px;
    }
    .close {
      right: 5px;
      top: 5px;
      background: white;
      opacity: 1;
      color: black;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
    }
    .scroll::-webkit-scrollbar {
      width: 7px;
    }
    .scroll {
      .product-list {
        height: auto;

        .product {
          max-width: 50%;

          .SLP-product-wrap {
            max-width: 100%;
            height: 150px;

            .SLP-priceWrap {
              overflow: hidden;
              height: auto;
              flex-direction: column;
            }

            .product-image {
              height: calc(100% - 47px);
            }
          }
        }
      }
    }
  }

  @media screen and (max-height: 480px) and (orientation: landscape) {
    max-height: 90%;
    max-width: 90%;
    .scroll::-webkit-scrollbar {
      width: 7px;
    }
    .scroll {
      & > .container-fluid.product-list {
        height: auto;

        .SLP-priceWrap {
          .product-name {
            max-width: 50%;
          }

          .product-price {
            max-width: 50%;
          }
        }
      }
    }
  }
}

#payment-form {
  .error-message {
    color: var(--color-secondary);
  }
}

.map-label-user {
  border-radius: 8px;
  background: green;
  padding: 5px;
}
.map-label {
  border-radius: 8px;
  background: red;
  padding: 5px;
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  #cart-popup, #e-commerce-product-popup {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 470px) {
  #cart-popup {
    left: 0!important;
  }
}
.minimap {
  margin-right: 50px!important; 
  @media screen and (max-height: 480px) and (orientation:landscape) {
    bottom: 2px!important;
  }
}