.game-book-button {
  position: absolute;
  right: 0;
  top: 90px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: #ffffff;
  width: 73px;
  height: 43px;
  margin: 9px 0 0;
  padding: 21px 10.8px 21px 24.9px;
  -webkit-backdrop-filter: blur(200px);
  backdrop-filter: blur(200px);
  background-color: rgba(0, 0, 0, 0.6);

  .book-image {
    position: absolute;
    top: -5px;
    left: 3px;
    width: 60px;
    height: 49px;
    background-image: url("/experience/book/btn.png");

    &:hover {
      background-image: url("/experience/book/btnHover.png");
      top: -33px;
      left: 0;
      width: 71px;
      height: 79px;
    }
  }

  &.mobile {
    top: 40px;
  }
}


