@import "_vars.scss";
.summary-block {
  overflow-y: auto;
  height: 100%;

  em {
      font-style: italic;
  }

  strong {
      font-weight: bold;
  }
}

#readMore {
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  color: #6ab9ff;
}
.slick {
  .readMore-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  #readMore {
    background: var(--color-secondary);
    color: #fff;
    font-size: 0.5em;
    cursor: pointer;
    padding: 12px 30px;
    text-transform: uppercase;
    width: fit-content;
    font-weight: normal;
    font-size: 14px;
  }
}
