.menu-mesh{
  &-wrapper{
    display: flex;
    flex-direction: column;
    background: #5e5e5e;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    border-radius: 8px;
    color: white;
    padding: 16px;

    width: 250px;

    z-index: -1;
    opacity: 0.9;
    font-family: 'Codec-Pro', sans-serif;
  }
  &-header{
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 4px;
    margin-top: 8px;
  }
  &-content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    font-size: 14px;
    max-width: 600px;
  }
  &-item{
    height: 36px;
    display: block;
    font-size: 1em;
    width: 100%;
    padding: 8px  8px;
    position: relative;
    margin: 0 auto;
    line-height: 1.4;
  }
}
