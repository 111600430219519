@import "_vars.scss";

#minimap {
  position: absolute !important;
  right: 10px;
  bottom: 90px;
  z-index: 2;
  transition: all 0.3s ease;
  cursor: pointer;
  height: 150px;
  padding: 10px;
  margin: 0;
  left: auto;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    right: 0;
  }

  @media screen and (max-width: 425px) {
    bottom: 130px;
  }

  .control-buttons {
    display: none;
    position: absolute;
    left: -40px;
    top: 0;
    flex-direction: column;

    .control-btn {
      height: 30px !important;
      width: 30px !important;
      min-height: 30px !important;
      min-width: 30px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      transition: all 0.3s ease;
      cursor: pointer;
      text-align: center;
      margin: 5px 5px 0;

      @media screen and (max-width: 768px) {
        width: 35px;
        height: 35px;
        background: rgba(0, 0, 0, 0.3);
      }
      &:hover,
      &.active {
        background: var(--color-secondary);
      }
      &:focus {
        outline: none;
      }
      svg,
      i {
        color: #fff;
        vertical-align: middle;
      }
      img {
        width: 18px;
      }
    }

    .fullscreen {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    .rotate-right {
      transform: scale(-1, 1);
    }
  }
  &:hover .control-buttons {
    display: flex;
  }
  .minimapC {
    width: -moz-max-content;
    width: max-content;
    height: 100%;
    @include hCenter(relative);
    text-align: center;
    overflow: hidden;
    .image-container {
      width: 100%;
      height: 100%;
    }
    & > div:first-child {
      width: 100%;
      height: 100%;
      & > div:first-child {
        width: 100%;
        height: 100%;
      }
    }
  }

  // Buttons in the zoom module - hiding them
  .iconButton {
    display: none;
  }

  &.narrow {
    height: 350px;
    @media screen and (max-width: 768px) {
      height: 180px;
    }
    @media screen and (max-height: 550px) and (min-width: 768px) {
      height: 180px;
    }
  }

  &.active {
    height: 400px;
    &.narrow {
      height: 535px;
    }
    @media screen and (max-height: 550px) and (min-width: 768px) {
      height: 300px;
      &.narrow {
        height: 300px;
      }
    }
    @media screen and (max-width: 768px) {
      height: 300px;
      &.narrow {
        height: 370px;
      }
    }

    #mapWalker {
      font-size: 20px;
    }
  }

  &.mobile {
    @media screen and (orientation:landscape) {
      bottom: 110px;
    }
  }

  img {
    height: 100%;
    width: auto;
    display: block;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  #mapWalker {
    position: absolute;
    background: transparent;
    border-radius: 50%;
    transition: all 0.3s ease;
    color: var(--color-secondary);
    font-size: 14px;
    z-index: 3;
    svg,
    i {
      background: #fff;
      border-radius: 50%;
    }
  }
  .marker {
    font-size: 14px;
    opacity: 0.35;
    display: none;
    z-index: 2;
    cursor: pointer;
    position: absolute;
    background: transparent;
    border-radius: 50%;
    color: #fff;
    transition: all 0.3s ease;
    svg,
    i {
      background: var(--color-secondary);
      border-radius: 50%;
    }
    &.hide {
      opacity: 0;
      &:hover {
        opacity: 1;
        display: block;
      }
    }
    &:hover,
    &.active {
      opacity: 1;
      display: block;
    }
    &:focus {
      outline: none;
    }
  }

  &:hover .marker {
    display: block !important;
  }

  .zero {
    transform: rotate(0deg);
  }
  .ninety {
    transform: rotate(90deg);
  }
  .flip {
    transform: rotate(180deg);
  }
  .twoSeventy {
    transform: rotate(270deg);
  }
  .minusNinety {
    transform: rotate(-90deg);
  }
}

#minimap.reel-open {
  bottom: 250px;

  @media screen and (max-width: 425px) {
    bottom: 290px;
  }
}

#minimap {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    bottom: 240px;
  }
}

#minimap.reel-open {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    bottom: 365px;
  }
}

