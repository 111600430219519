.circle-input {
  &-wrapper {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 2px solid white;
    overflow: hidden;
    margin-right: 8px;
  }
  &-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
