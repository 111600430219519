@import './_vars.scss';

@mixin gb-guide-background {
  backdrop-filter: blur(300px);
  border: solid 1px var(--white);
  background-color: rgba(0, 0, 0, 0.6);
}
.gb-guide-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  position: absolute;
  z-index: 130;
  &.mobile {
    @include mobileHeight;
  }
}
.gb-guide {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 1042px;
  height: -moz-fit-content;
  height: fit-content;
  color: white;
  z-index: 150;
  transform: scale(0.85);
  &-logo {
    position: absolute;
    margin-top: 21px;
    margin-left: 29px;
    background-size: cover;
    max-width: 175px;
    width: auto;
    height: 61px;
  }
  &-title {
    border-radius: 20px 20px 0 0;
    padding: 10px 45px;
    @include gb-guide-background;
    display: flex;
    align-items: center;
    border-bottom: none;
    width: fit-content;
    width: -moz-fit-content;
    border-bottom: 0;

    .line {
      width: 50px;
      height: 2px;
      flex-grow: 0;
      background-color: var(--white);
    }

    .title-label {
      width: 83px;
      height: 35px;
      flex-grow: 0;
      font-family: WorkSans;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.6px;
      text-align: center;
      color: var(--white);
    }
  }
  &-content {
    height: auto;
    border-radius: 0 20px 20px 20px;
    padding: 20px 45px;
    @include gb-guide-background;

    .content-title {
      display: flex;
      justify-content: center;
      font-family: WorkSans;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.6px;
      text-align: center;
      color: var(--white);
    }

    .content-wrapper {
      display: flex;
      justify-content: center;
    }
    .content-group {
      max-width: 235px;
      padding: 10px 30px;
      height: 220px;
      text-align: center;
      &.item-3 {
        max-width: 320px;
      }

      .image {
        height: 110px;
        background-repeat: no-repeat;
        background-position: bottom;
        margin-bottom: 10px;

        &.one {
          background-image: url("/experience/guide/round.png");
        }
        &.two {
          background-image: url("/experience/guide/arrows.png");
        }
        &.three {
          background-image: url("/experience/guide/mouse.svg");
        }
        &.four {
          background-image: url("/experience/guide/space.svg");
        }
        &.five {
          background-image: url("/experience/guide/play.svg");
        }
        &.six {
          background-image: url("/experience/guide/btn.png");
        }
        &.seven {
          display: flex;
          justify-content: center;
          align-items: end;
          pointer-events: none;
        }
      }

      .text {
        flex-grow: 0;
        margin: 19px 0 0;
        font-family: WorkSans;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.34px;
        text-align: center;
        color: var(--white);
      }
    }

    .content-line {
      width: 100%;
      height: 1px;
      background-color: var(--white);
      margin-bottom: 10px;
    }
  }
  .next-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 150px;
    background: #EA475C;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    margin-left: 13px;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #EA475C;
      border: solid 1px var(--white);
      background-color: var(--white);
    }

    &.no-btn {
      cursor: none;
      width: 109px;
      height: 42px;
      font-size: 12px;
      margin-left: 0;
    }

    i {
      font-size: 20px;
      margin-left: 15px;
    }
  }

  &.mobile {
    width: 100vw;
    height: 100%;

    overflow-y: auto;
    .gb-guide-logo {
      max-width: 100px;
      height: 35px;
      z-index: 10;
    }
    .gb-guide-title {
      border-radius: 0;
      position: absolute;
      border: none;
      z-index: 10;
      background: transparent;
      backdrop-filter: none;
      padding: 0;
      right: 0;
      border-bottom: 2px solid white;
      margin: 15px 35px 0 0;
      .title-label {
        font-size: 25px;
      }
    }
    .gb-guide-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      border: none;
      padding: 25px 10px;
      height: 100%;
      .content-title {
        font-size: 20px;
      }
      .content-wrapper {
        flex-wrap: wrap;
        margin: 20px 0;
      }
      .content-group {
        max-width: 85px;
        padding: 0 10px;
        height: 100%;

        @media (min-width: 720px) {
          max-width: 130px;
        }

        &.item-3 {
          max-width: 100px;

          @media (min-width: 720px) {
            max-width: 180px;
          }
        }
        .image {
          height: 20px;
          background-size: contain;
          @media (min-width: 720px) {
            height: 62px;
          }
        }
        .text {
          font-size: 10px;
          @media (min-width: 720px) {
            font-size: 21px;
          }
        }
      }
      .next-btn.no-btn {
        width: 60px;
        height: 25px;
        font-size: 7px;

        @media (min-width: 720px) {
          width: 100%;
          height: 40px;
          font-size: 18px;
          i {
            font-size: 22px !important;
          }
        }
        i {
          font-size: 8px;
        }
      }
      .align-center {
        padding-bottom: 20px;
        position: relative;
      }
      &.horizontal {
        padding: 0;
        flex-direction: row;
        .guide-footer {
          position: absolute;
          bottom: 10px;
          .next-btn {
            height: 35px;
            width: 130px;
          }
        }
      }
    }
  }
}
