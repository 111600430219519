$custom-color: #319FD8;

.horizontal-tabs {
  .tab-section-title {
    height: 46px;
    background-color: #f4f4f4;
    color: var(--color-primary);
    cursor: pointer;
    width: 100%;
    line-height: 45px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .3s;
    margin-bottom: 15px;

    .tab-title {
      i {
        margin-right: 10px;
        color: $custom-color;
      }
    }

    &.active {
      background-color: $custom-color;
      color: #fff;
      i {
        color: #ffffff;
      }
    }
  }

  .tab-section-content {
    padding: 0 20px;

    &.hide {
      display: none;
    }
  }
}
