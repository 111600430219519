@import "_vars.scss";

// html,
// body,
// body > div:first-child,
// div#__next,
// div#__next > div,
// div#__next > div > div {
//   height: 100%;
// }

#login-root {
  height: 100vh;
  width: 100%;
  background: #f8f8f8;
  .left {
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    position: relative;
    float: left;
  }
  a {
    color: #fff;
    display: block;
    cursor: pointer;
    @include hCenter(relative);
    margin: 30px auto;
    width: 100% !important;
    &:hover {
      color: var(--color-secondary) !important;
    }
  }
  form {
    width: 400px;
    height: 450px;
    @include superCenter(absolute);
    text-align: center;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
    img {
      width: 200px;
      margin-bottom: 20px;
    }
    input {
      text-align: left;
    }
    button {
      width: 100%;
      margin-top: 10px;
    }
  }
}

#conference {
  overflow: auto;
  overflow-x: hidden;
  .form-align-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  form {
    height: auto;
    position: initial;
    select {
      height: 50px;
      font-size: 0.9em;
      background: #fff;
      border: 1px solid #000;
      border-radius: 4px;
      display: block;
      font-family: "Open Sans Hebrew", sans-serif;
    }
  }
  .register-link {
    color: #fff;
    display: inline-block;
    cursor: pointer;
    position: relative;
    right: 0;
    left: 0;
    margin: 30px auto;
    width: 100%;
    text-align: center;
  }

  .rtl .field-block {
    text-align: right;
    direction: rtl;
  }

  .field-block {
    margin-bottom: 20px;

    .block-input {
      height: 35px;
      padding-top: 9px;
      margin: 5px;
      font-size: 1rem;
      border: 1px solid #eee;
    }
    input[type="checkbox"] {
      height: auto;
      width: auto;
      display: inline;
      margin-right: 10px;
      float: left;
    }
    label {
      margin-top: 3px;
      color: #ffffff;
    }
  }

  .rtl .field-block input[type="checkbox"] {
    float: right;
    margin-left: 10px;
    margin-right: 0;
  }
}

.video-popup-wrap iframe{
  max-width: 100%;
}
.video-popup-wrap{
  max-width: 100%;
  width: 640px;
  height: 360px;
  padding: 0px;

}