.menu-search-container {
  display: flex;
  margin-left: 25px;
  margin-bottom: 30px;
  padding-right: 40px;
  border-radius: 7px;
  height: 40px;
  width: 250px;
  position: relative;

  input {
    height: 40px;
    border-radius: 7px 0 0 7px;
    background: rgba(248, 248, 248, 0.5);
    color: white;
    margin-top: 0;
    padding-right: 20px;
    font-family: "Heebo", sans-serif !important;

    &::placeholder {
      color: var(--color-primary);
      font-size: 16px;
    }
  }

  .fa-times {
    position: absolute;
    left: 158px;
    top: 13px;
    color: rgba(0, 0, 0, 0.6);
    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
  .input-group-text {
    background: var(--color-secondary);
    color: #fff;
    border: 0;
    border-radius: 0 7px 7px 0;
    width: 40px;
    justify-content: center;
    height: 40px;
  }
  &.rtl {
    input {
      border-radius: 0 7px 7px 0;
      padding-left: 20px;
      padding-right: 0;
    }
    .input-group-text {
      border-radius: 7px 0 0 7px;
    }
    .fa-times {
      left: 42px;
    }
  }
}
.horizontal-modile-menu-view {
  &.open {
    top: 0;
    padding-top: 3vh;
    #book {
      margin-bottom: 3vh;
    }
    .menu-search-container {
      margin-bottom: 3vh;
    }
    h2 {
      margin-bottom: 3vh;
    }
    .appointmentButton {
      margin-bottom: 3vh;
    }
    .contact {
      margin: 3vh 0;
      span {
        margin-bottom: 3vh;
      }
    }
  }
}
#menu .loadModel {
  overflow: hidden;
  text-overflow: ellipsis;
}
