@keyframes kioskWrapperFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes kioskWrapperFadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .wrapper-visible {
    animation: kioskWrapperFadeIn 0.5s forwards;
  }
  
  .wrapper-hidden {
    animation: kioskWrapperFadeOut 0.5s forwards;
  }