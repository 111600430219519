.numeric-keypad {
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #EEEEEE;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(6, 40px);
  grid-template-rows: repeat(6, 60px);
  grid-gap: 5px;
  border: 10px solid #707070;
  font-family: Montserrat;
  font-size: 30px;
  text-align: center;

  .closeIcn {
    display: flex;
    position: absolute;
    right: -25px;
    top: -25px;
    background: black;
    color: white;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      color: var(--color-secondary);
    }
  }

  .key {
    border: 2px solid;
    border-radius: 5px;
    grid-column: span 2;
    font-size: 20px;
    text-align: center;

    background: rgb(0,0,0);
    background: linear-gradient(to right bottom, rgba(0,0,0,0.1292892156862745) 16%, rgba(112,112,112,1) 100%, rgba(152,152,152,1) 100%);
    border-image-source: linear-gradient(to right bottom, rgba(148,148,148,1) 100%, rgba(112,112,112,1) 100%);

    &:hover {
      border: 1px solid #eeeeee;
    }
  }

  .input {
    font-family: Montserrat;
    font-size: 25px;
    background: #D0D0D0;
    border: none;
    grid-column: span 6;

    &:focus {
      outline-color: #D0D0D0;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }



  .header {
    grid-column: span 6;
  }
}
