@mixin hCenter($position) {
    position: $position;
    right: 0;
    left: 0;
    margin: 0 auto;
}

@mixin vCenter($position) {
    position: $position;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

@mixin superCenter($position) {
    position: $position;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

@mixin fullpage {
    height: 100%;
    width: 100%;
    background-color: #333;
}

@mixin mobileHeight {
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
}

@mixin mobileHeightImportant {
  height: calc(var(--vh, 1vh) * 100) !important;
  max-height: calc(var(--vh, 1vh) * 100) !important;
}
