@import "_vars.scss";

#public-asset-root.slick {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Heebo", sans-serif;
    font-style: initial !important;
    color: #000;
  }

  .slick-header {
    z-index: 1;
    .header-logo {
      height: 100%;
    }
    .logo {
      height: 90%;
    }
    .navi {
      text-transform: uppercase;
      line-height: initial;
      .schedule-btn {
        background: var(--color-secondary);
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        opacity: 1;
        padding: 4px 16px;
        transition: all 0.3s ease;
        text-transform: initial;
      }
    }
  }

  .intro {
    .main-slider {
      z-index: 2;
      opacity: 0.82;
    }
    .slider-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: black;
    }
  }
  .slider-content {
    z-index: 3;
    position: absolute;
    top: 50%;
    color: white;
    font-size: 2em;
    padding: 0 5% 0 10%;
    p {
      color: inherit;
      text-align: start;
      font-weight: bold;
    }
    .address {
      background: var(--color-primary);
      color: #fff;
      padding: 12px 16px;
      font-size: 0.5em;
      text-transform: uppercase;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }
    .tour-title {
      font-size: 2em;
    }
    .price {
      font-size: 1.5em;
    }
    .price-lbl,
    .price-old {
      font-size: 0.75em;
    }
    .price-old {
      text-decoration: line-through;
      color: #9c9c9c;
    }
    .option {
      font-size: 0.75em;
      color: var(--color-secondary);
      margin-top: 20px;
      height: 60px;
      width: 60px;
      background: #fff;
      cursor: pointer;
      &:hover {
        border: 1px solid var(--color-secondary);
        background: #ffecee;
      }
    }
  }

  .info-wrapper {
    z-index: 10000000000 !important;
    position: absolute;
    margin-top: -100px;
    .info-box {
      min-height: 150px;
      background: #fff;
      border: 1px solid #eee;
      flex-wrap: wrap;
      .iconWrapper {
        height: 50px;
        width: 50px;
        background: #ffecee;
        transform: translateY(-3px);
        svg,
        i {
          font-size: 1.5em;
          color: var(--color-secondary);
        }
      }
      .amenity {
        font-size: 1em;
        color: #444444;
        text-transform: uppercase;
        text-align: left;
        margin-bottom: 0px !important;
      }
      .number {
        font-size: 1.50em;
        font-weight: bold;
        color: #000;
        text-align: left;
        margin-bottom: 0px !important;
      }
    }
  }

  .details {
    background: white;
    padding: 100px 0 50px 0;
    .summary {
      font-size: 1.5em;
      color: #000;
    }

    #gallery {
      .gcol {
        height: 40vh;
        margin-top: 10px;
        padding-right: 5px;
        padding-left: 5px;
        display: flex;
        align-items: flex-end;
        .img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          background-position: center;
          background-size: cover;
        }
      }
      .align-start {
        &.gcol {
          align-items: flex-start;
        }
      }
      /* large */
      .gcol-1,
      .gcol-4 {
        flex: 0 0 50%;
        max-width: 50%;
        .img {
          height: 100%;
        }
      }
      /* middle */
      .gcol-0,
      .gcol-2 {
        flex: 0 0 25%;
        max-width: 25%;
        .img {
          height: 75%;
        }
      }
      /* small */
      .gcol-3,
      .gcol-5 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
        .img {
          height: 50%;
        }
      }
    }

    .slick-section {
      min-height: 200px;
      padding: 50px 0;
      .title {
        font-size: 2em;
        margin-bottom: 30px;
        padding: 0;
      }
    }
    .grey-section {
      background: #f5f5f5;
    }
  }

  #tourScheduler {
    padding: 50px 0;
    .date {
      min-height: 100px;
      min-width: 100px;
      background: #fff;
      border: 1px solid #eee;
      text-transform: uppercase;
      cursor: pointer;
      text-align: center;
      font-size: 10px;
      color: #000;
      .day {
        font-size: 2em;
        font-weight: bold;
        transform: translateY(2px);
      }
    }
    .selected > .date {
      border: 1px solid var(--color-secondary);
      background: #ffecee;
    }
    .buttons {
      text-transform: none !important;
      .btn {
        cursor: pointer;
        padding: 16px 30px;
        margin-bottom: 15px;
        width: fit-content;
        background: #fff;
        border: 1px solid #eee;
        border-radius: 0;
        width: 100% !important;
        align-content: center !important;
        justify-content: center !important;
        svg,
        i {
          transform: translateY(2px);
        }
        &.selected {
          border: 1px solid var(--color-secondary);
          background: #ffecee;
          svg,
          i {
            color: var(--color-secondary);
          }
        }
        &.secondary {
          background: var(--color-secondary);
          color: #fff;
        }
      }
    }
    .chevron-wrapper {
      cursor: pointer;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      font-size: 10px;
      color: grey;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 5px 8px -3px rgba(160, 158, 158, 0.5);
    }
    .info {
      margin-top: 30px;
      color: #000000a8;
      font-size: 0.9em;
    }
  }

  .video {
    width: 100%;
    height: 400px;
  }
  .amenity-img {
    height: 400px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  #tour-floorPlan {
    .tour {
      height: auto;
    }
    .tour .inner {
      height: 600px;
    }
    .title {
      cursor: pointer;
      color: #00000061;
    }
    .active {
      border-bottom: 3px solid var(--color-secondary);
      color: #000;
    }
  }

  #contact {
    .tab-title {
      text-align: left;
      font-size: 1.2em;
      text-transform: uppercase;
    }
    .agents {
      min-height: 300px;
      width: 100%;
      .agent-wrapper {
        margin-bottom: 10px;
        padding: 1em;
        cursor: pointer;
      }
      .agent-profile {
        height: 50px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .agent-info {
        text-transform: uppercase;
        overflow: hidden;
        p {
          font-weight: normal;
          font-size: 10px;
          margin: 3px 0;
        }
        .name {
          font-weight: bold;
        }
      }
      .selected {
        .agent-wrapper {
          box-shadow: 0 5px 8px -3px rgba(160, 158, 158, 0.5);
        }
      }
    }
    .contact-form {
      input {
        border: 1px solid #eee;
        margin: 0 0 10px 0;
        padding: 8px !important;
        &.name {
          width: 49%;
        }
      }
    }
  }
  footer {
    background: var(--color-primary);
    position: relative;
    .slick-logo {
      height: 90%;
      @include vCenter(absolute);
    }
    a {
      color: grey;
      font-size: 1.5em;
      margin-right: 15px;
      float: right;
      &:hover {
        color: var(--color-secondary);
      }
    }
  }
  .secondary-btn {
    background: var(--color-secondary);
    color: #fff;
    cursor: pointer;
    padding: 12px 30px;
    text-transform: uppercase;
    width: fit-content;
    &.submit {
      background: var(--color-primary);
      text-transform: none;
    }
  }

  .tour .overlay .text {
    top: calc(50% + 100px);
    bottom: 50%;
  }
}

.inner-tab.tab-1 {
  height: 600px;
}
