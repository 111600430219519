.collect-leads-modal {
  height: 470px !important;
  width: 720px !important;
  &.big {
    height: 550px !important;
  }
  @media screen and (max-width: 768px) {
    height: 100vh !important;
    width: 100% !important;
    &.big {
      height: 100vh !important;
    }
  }

  .content {
    display: flex;
    height: 100%;
    background: #fff;
    position: relative;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      display: block;
    }

    .left,
    .right {
      flex: 1 1 50%;
      width: 50%;

      @media screen and (max-width: 768px) {
        width: 100%;
        display: block;
      }
    }

    .left {
      position: relative;
      @media screen and (max-width: 768px) {
        height: 200px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .logo-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      a {
        max-width: 60%;
        max-height: 60%;

        img {
          object-fit: contain;
          @media screen and (max-width: 768px) {
            height: 100px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      padding: 10px;
      @media screen and (max-width: 768px) {
        display: block;
        height: calc(100vh - 200px);
        overflow-y: scroll;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
          background: var(--color-primary);
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background: var(--color-secondary);
        }
        &::-webkit-scrollbar {
          width: var(--scroll-bar-size);
          height: 4px;
          background: var(--color-primary);
        }
      }
    }

    .close-btn {
      border-radius: 50%;
      position: absolute;
      top: 5px;
      right: 5px;
      background: transparent;
      padding: 0;
      z-index: 10;

      i {
        color: #8d8d8d;
        position: relative;
        top: unset;
        right: unset;
        background: transparent;
      }
    }

    h3 {
      margin-bottom: 20px;
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 1.2;
      margin-top: 0;
    }
    p {
      line-height: 1.4;
      margin-bottom: 10px;
      font-size: 1em;
      max-height: 160px;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background: var(--color-primary);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: var(--color-secondary);
      }
      &::-webkit-scrollbar {
        width: var(--scroll-bar-size);
        height: 4px;
        background: var(--color-primary);
      }
      @media screen and (max-width: 768px) {
        overflow-y: visible;
        max-height: none;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
      @media screen and (max-width: 768px) {
        display: block;
      }
      .field {
        margin: 10px 0 0;
        @media screen and (max-width: 768px) {
          display: block;
        }
        input {
          height: 40px;
          border: 1px solid #e0e0e0;
          margin-top: 10px;
          @media screen and (max-width: 768px) {
            display: block;
          }
        }
      }
    }
    .start-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      background: var(--color-secondary);
      color: #fff;
      margin-top: auto;
      @media screen and (max-width: 768px) {
        display: block;
        margin-bottom: 20px;
        line-height: 40px;
      }
    }
  }
}
