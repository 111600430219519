@import "_vars.scss";


#book-appointment-asset-root {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Heebo", sans-serif;
    font-style: initial !important;
    color: #000;
  }

  .padding-bottom{
      padding-bottom: 1rem;
  }

  .center-content{
    margin: auto;
    width: 50%;
  }

  .slick-header {
    z-index: 1;
    .header-logo {
      height: 100%;
    }
    .logo {
      height: 90%;
    }
    .navi {
      text-transform: uppercase;
      line-height: initial;
      .schedule-btn {
        background: var(--color-secondary);
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        opacity: 1;
        padding: 4px 16px;
        transition: all 0.3s ease;
        text-transform: initial;
      }
    }
  }

  .intro {
    .main-slider {
      z-index: 2;
      opacity: 0.6;
    }
    .slider-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: black;
    }
  }
  .leftContent{
      padding-right: 0px;
      max-width: 300px;
  }
  .rightContent{
    padding-left: 0px
}
  .agent-wrapper {
    margin-bottom: 10px;
    padding: 2em;
    cursor: pointer;
    background-color:rgb(255, 255, 255);
    justify-content: center;
    min-width: 250px;
  }
  .imageDesc{
      margin: 10px 0px;
      color: grey;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }

  .agent-info {
    text-transform: uppercase;
    p {
      font-weight: normal;
      font-size: 10px;
      margin: 3px 0;
    }
    .name {
      font-weight: bold;
    }
  }

  .details {
    justify-content: center;
    background: white;
    .slick-section {
      min-height: 200px;
      padding: 50px 0;
      .title {
        font-size: 1.2em;
        margin-bottom: 15px;
        margin-top: 15px;
        padding-left: 50px
      }
      .title_left{
        font-size: 1.2em;
        margin-bottom: 15px;
        margin-top: 15px;
      }
    }
    .grey-section {
      background: #f5f5f5;
    }
  }

  #tourScheduler {
    padding: 50px 0;
    .date {
      min-height: 120px;
      min-width: 100px;
      background: #fff;
      border: 1px solid #eee;
      text-transform: uppercase;
      cursor: pointer;
      text-align: center;
      font-size: 10px;
      color: #000;
      .day {
        font-size: 2em;
        font-weight: bold;
        transform: translateY(2px);
      }
    }
    .selected > .date {
      border: 1px solid var(--color-secondary);
      background: #ffecee;
    }

    .dateSlot{
        padding: 3px;
    }

    .dtimeSlot{
        padding: 3px;
    }

    .time {
        min-height: 45px;
        min-width: 100px;
        background: #fff;
        border: 1px solid #eee;
        text-transform: uppercase;
        cursor: pointer;
        text-align: center;
        font-size: 10px;
        color: #000;
        padding: 5px 15px;
      }
      .selected > .time {
        border: 1px solid var(--color-secondary);
        background: #ffecee;
      }
    
    h6{
      margin-bottom: 0px;
    }
    
    .parentTypeTour{
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }
    .tourTypeButtonLeft{
        max-width: 203px;
        max-height: 55px;
        margin-right: 3px;
        margin-left: 50px;
        display: inline-block;
    }

    .tourTypeButtonRight{
        margin-right: 50px;
        margin-left: 3px;
        max-width: 206px;
        max-height: 55px;
        display: inline-block;
    }

    .btn {
        cursor: pointer;
        padding: 16px 30px;
        margin-bottom: 15px;
        width: fit-content;
        background: #fff;
        border: 1px solid #eee;
        border-radius: 0;
        width: 100% !important;
        align-content: center !important;
        justify-content: center !important;
        i {
          transform: translateY(2px);
        }
        &.selected {
          border: 1px solid var(--color-secondary);
          background: #ffecee;
          i {
            color: var(--color-secondary);
          }
        }
        &.secondary {
          background: var(--color-secondary);
          color: #fff;
        }
    }

    .chevron-wrapper {
      cursor: pointer;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      font-size: 10px;
      color: grey;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 5px 8px -3px rgba(160, 158, 158, 0.5);
    }
    .info {
      margin-top: 30px;
      color: #000000a8;
      font-size: 0.9em;
    }
  }

  .video {
    width: 100%;
    height: 400px;
  }
  .amenity-img {
    height: 400px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .footerButton{
    width: 150px;
  }
  .bookNow:active {
    border: 1px solid grey;
}
  footer {
    padding-top: 15px;
    justify-content: center;
    padding-bottom: 1rem;
  }
  .bookNow{
    cursor: pointer;
    background: var(--color-secondary);
  }
  .secondary-btn {
    background: var(--color-secondary);
    color: #fff;
    cursor: pointer;
    padding: 12px 30px;
    text-transform: uppercase;
    width: fit-content;
    &.submit {
      background: var(--color-primary);
      text-transform: none;
    }
  }
}

.inner-tab.tab-1 {
  height: 400px;
}
