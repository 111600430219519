#share-pop {
  height: auto;
  width: 300px;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: auto;
  right: 120px;
  top: auto;
  bottom: 70px;
  padding: 20px;
  z-index: 1000;

  button {
    line-height: 0;
  }

  @media screen and (max-width: 768px) {
    background: #fff;
    bottom: 0;
    right: 0;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    text-align: center;
    z-index: 1000;
  }

  h3 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 18px;
    @media screen and (max-width: 768px) {
      color: #000;
      text-align: left;
    }
  }

  .fa-times {
    color: #fff;
    font-size: 15px;
  }

  .close {
    background: none !important;
    margin: 0;
    height: auto;
    width: auto;
    line-height: 1;
    top: 10px;
    right: 15px;
    position: absolute;
    @media screen and (max-width: 768px) {
      svg,
      i {
        color: #000;
      }
    }
  }

  .switchLabel {
    color: #fff;
    margin-top: 10px;
    @media screen and (max-width: 768px) {
      position: relative;
      width: 100%;
      color: #000;
      text-align: left;
    }

    & > div {
      position: relative;
      top: 5px;
      margin-left: 5px;
      @media screen and (max-width: 768px) {
        position: absolute !important;
        right: 0 !important;
      }
    }
  }

  button {
    background: #e5e5e5;
    border-radius: 50%;
    padding: 8px 10px;
    margin: 0 5px;
    height: 40px;
    width: 40px;
    @media screen and (max-width: 768px) {
      margin: 0 10px;
    }

    &:hover,
    &.active {
      background: var(--color-secondary);

      svg,
      i {
        color: #fff;
      }
    }

    svg,
    i {
      height: 18px;
      font-size: 16px;
      vertical-align: middle;
    }
  }
}
