.flows-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #2C3038;
  padding: 12px;
  width: 800px;
  height: 108px;
  gap: 8px;
  font-size: 14px;
  line-height: 20px;
  z-index: -1;
  font-family: 'Heebo', sans-serif;
  color: #FFFFFFCC;

  &-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-title {
    display: block;
    font-weight: bold;
    font-size: 16px;
    color: white;
  }

  &-description {
    display: block;
  }

  &-button {
    display: flex;
    width: 42px;
    height: 42px;
    border-radius: 35px;
    background-color: white;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

    i {
      color:  #2055FF
    }

    &-wide {
      width: auto;
      background: #2055FF;
    }
  }
}
