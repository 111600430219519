/* heebo-100 - latin */
  @font-face {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../../public/fonts/Heebo/heebo-v21-latin-100.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* heebo-300 - latin */
  @font-face {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../public/fonts/Heebo/heebo-v21-latin-300.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* heebo-600 - latin */
  @font-face {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../../public/fonts/Heebo/heebo-v21-latin-600.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* heebo-100 - hebrew */
  @font-face {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../../public/fonts/Heebo/heebo-v21-hebrew-100.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* heebo-300 - hebrew */
  @font-face {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../public/fonts/Heebo/heebo-v21-hebrew-300.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* heebo-600 - hebrew */
  @font-face {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../../public/fonts/Heebo/heebo-v21-hebrew-600.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* work-sans-regular - latin */
  @font-face {
    font-family: 'Work Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../public/fonts/Work/work-sans-v18-latin-regular.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* work-sans-700 - latin */
  @font-face {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:  url('../../public/fonts/Work/work-sans-v18-latin-regular.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* work-sans-regular - latin-ext */
  @font-face {
    font-family: 'Work Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:  url('../../public/fonts/Work/work-sans-v18-latin-ext-regular.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* work-sans-700 - latin-ext */
  @font-face {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:  url('../../public/fonts/Work/work-sans-v18-latin-ext-700.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;

  }

  /* work-sans-regular - vietnamese */
  @font-face {
    font-family: 'Work Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../public/fonts/Work/work-sans-v18-vietnamese-regular.woff2') format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }

  /* work-sans-700 - vietnamese */
  @font-face {
    font-family: 'Work Sans',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../../public/fonts/Work/work-sans-v18-vietnamese-700.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {font-family: "Impact"; src: url("//db.onlinewebfonts.com/t/4cbf858ccd9ffd3923bed6843a474080.eot"); src: url("//db.onlinewebfonts.com/t/4cbf858ccd9ffd3923bed6843a474080.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/4cbf858ccd9ffd3923bed6843a474080.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/4cbf858ccd9ffd3923bed6843a474080.woff") format("woff"), url("//db.onlinewebfonts.com/t/4cbf858ccd9ffd3923bed6843a474080.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/4cbf858ccd9ffd3923bed6843a474080.svg#Impact") format("svg"); }

  /* Codec-Pro - regular */
  @font-face {
    font-family: 'Codec-Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../public/fonts/Codec-Pro/Codec-Pro-Regular-trial.ttf') format('truetype');
  }
