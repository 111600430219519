.backpack-mesh{
  display: flex;
  flex-direction: column;
  background: #5e5e5e;
  border-radius: 16px;
  margin: auto;
  padding: 8px;

  &-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    height: 1024px;
    width: 1024px;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    color: white;
    padding: 16px;
    z-index: -1;
    font-family: 'Codec-Pro', sans-serif;
  }
  &-header{
    text-align: left;
    font-weight: bold;
    font-size: 30px;
    margin: 16px 16px 8px 16px;
  }
  &-content{
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    max-width: 600px;
  }
  &-item{
    display: block;
    padding: 8px 16px;
    position: relative;
    margin: 0 auto;
    line-height: 1.4;
    font-size: 24px;
  }
}
