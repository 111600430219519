@font-face {
  font-family:andale mono;
  font-style:normal;
  font-weight:400;
  src:local('Andale Mono'),
  url("./font.woff") format('woff')
}

.font-andale_mono {
  font-family: "Andale Mono";
}