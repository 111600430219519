.ql-editor {
  font-size: 16px;
  color: black;
  border: none;
  word-break: break-word;
  white-space: pre-wrap;

  &.ql-blank::before {
    font-style: normal;
    font-size: 16px;
    color: #9F9F9F;
    line-height: 24px;
  }
}
.ql-tooltip.ql-editing {
  transform: translateX(40px);
  margin-left: 40px;
  z-index: 1400;
}