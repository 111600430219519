#left-menu {
  height: 25vh;
  max-height: 40vh;
  font-size: 0.9em;
  margin-bottom: 20px;

  .subSubMenus, .subMenus, .menus {
    position: absolute;
    width: 250px;
    overflow-y: auto;
    height: inherit;
    .mainLink {
      i {
        float: right;
      }
      &.open {
        color: var(--color-secondary);
        &> i {
          transform: rotate(90deg);
        }
      }
    }
  }
  .subSubMenus {
    right: 450px;
    width: 200px;
    bottom: 65px;
    background: rgba(0, 0, 0, 0.6);
  }
  .subMenus {
    right: 250px;
    width: 200px;
    bottom: 65px;
    background: rgba(0, 0, 0, 0.6);
  }
  .menus {
    right: 0;
  }
}
