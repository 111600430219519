.add-appointment {
  background: #fff;
  padding: 28px 33px 21px 33px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.appointment-terms {
  text-align: center;
  color: blue;
  padding: 10px 0;
  display: block;
}

.appointment-main {
  height: 620px !important;
  width: 540px !important;
  max-height: calc(100vh - 30px);
  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .primary-btn {
    width: 100%;
    margin-top: 10px;
  }
}

.appointment-mobile {
  height: 100% !important;
  width: 100% !important;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  bottom: 0;
  top: auto;
  overflow-y: auto;
  overflow-x: hidden;
  .scroll {
    max-height: 100%;
  }
  @media screen and (orientation: portrait) {
    .appointment-block {
      .block-head {
        font-size: 1rem;
      }
      .block-input {
        font-size: 1rem;
        height: 40px;
      }
      .date {
        min-width: 80px;
        padding: 7px 0 0 0;
      }
    }
    .primary-btn {
      width: 100%;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 359px) and (orientation: portrait) {
    .appointment-head {
      h2 {
        font-size: 1.2em !important;
      }
    }
    .mt-3 {
      margin-top: 0 !important;
    }
    .appointment-block {
      .block-input {
        margin: 0;
      }
      .date-slot {
        padding: 0;
      }
      .date {
        min-width: 72px;
      }
    }
  }
  @media screen and (orientation: landscape) {
    height: 90% !important;
    width: 90% !important;
    overflow-y: auto;

    .fa-times {
      right: 0 !important;
      top: 0 !important;
    }

    .primary-btn {
      margin: 20px 0;
    }
  }
}

.rtl .appointment-head {
  direction: rtl;
  text-align: right;
  .fa-times {
    right: auto;
    left: -15px;
    @media screen and (max-width: 768px) {
      right: auto !important;
      left: 0 !important;
    }
  }
}

.appointment-head {
  .fa-times {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 3;
    top: -15px;
    right: -15px;
    border-radius: 50%;
    background: #000;
    opacity: 1;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    transition: all 0.3s ease;
    &:hover {
      color: var(--color-secondary);
    }
    @media screen and (max-width: 768px) {
      top: 0 !important;
      right: 0 !important;
    }
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h2 {
      padding: 0 !important;
      font-size: 1.45rem;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.5px;
    }

    span {
      font-size: 2rem;
    }
  }
  span {
    font-size: 14px;
    color: grey;
  }
}

.rtl .appointment-block {
  text-align: right;
  direction: rtl;
  label {
    text-align: right;
    direction: rtl;
  }
}

.appointment-block {
  .block-head {
    display: flex;
    font-size: 1.1rem;
    justify-content: space-between;

    :nth-child(2) {
      margin-top: 3px;
      color: grey;
    }
  }

  .block-input {
    height: 35px;
    padding-top: 9px;
    margin: 5px;
    font-size: 1rem;
    border: 1px solid #eee;
  }
  input[type="checkbox"] {
    height: auto;
    width: auto;
    display: inline;
    margin-right: 10px;
    float: left;
  }
  label {
    margin-top: 3px;
  }
}

.appointment-mobile .appointment-block label {
  font-size: 12px;
  margin-top: 15px;
}
.rtl .appointment-block input[type="checkbox"] {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}

.close-line {
  margin: -10px auto 40px auto;
  width: 60px;
  height: 3px;
  background: grey;
  border-radius: 1px;
  top: 0;
}

.close-rect {
  height: 100px;
  position: absolute;
  width: 100vw;
  top: 0;
  right: 0;
}

.date-slot {
  padding: 3px;
}

.date {
  min-height: 45px;
  min-width: 100px;
  background: #eee;
  cursor: pointer;
  text-align: center;
  color: #000;
  padding: 7px 15px 0 15px;
}
.selected > .date {
  background: var(--color-secondary);
  color: #fff;
}

.chevron-wrapper {
  cursor: pointer;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  font-size: 10px;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 8px -3px rgba(160, 158, 158, 0.5);

  &:active {
    background: var(--color-secondary);
  }
}

.chevron-wrapper-mobile {
  height: 30px;
  width: 30px;
  font-size: 10px;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  cursor: pointer;
}
