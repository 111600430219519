.backpack-popup{
  &-wrapper{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    color: white;

    //width: 250px;
    z-index: -1;
    opacity: 0.9;
    font-family: 'Codec-Pro', sans-serif;
  }
  &-content{
    padding: 16px;
    border-radius: 8px;
    background: #5e5e5e;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    font-size: 32px;
  }
  &-item{
    display: block;
    font-size: 32px;
    padding: 8px  8px;
    position: relative;
    margin: 0 auto;
    line-height: 1.4;
  }
}
