@mixin avatar-text() {
  .circle {
    background: #cccccc;
    border-radius: 50%;
    height: 40px;
    text-align: center;
    width: 40px;
    font-weight: bold;
  }

  .initials {
    font-size: calc(40px / 2);
    line-height: 1;
    position: relative;
    top: calc(40px / 4);
    text-transform: uppercase;
  }
}

#public-tour-root {
  .video-call-no-permission {
    display: block;
    margin-bottom: 10px;
  }
  #conference-chat.chat {
    display: block;
    padding: 0;
    border-radius: 8px;
    left: calc(50% - 175px);
    bottom: 0;
    top: calc(50% - 30vh);

    @media screen and (max-width: 768px) {
      background: rgba(0, 0, 0, 0.7);
      left: 0;
      top: calc(50% - 250px);
      width: 90%;
      margin: 0 auto;
    }

    .socket-connection {
      color: #fff;
      font-size: 10px;
      padding: 0 10px;
      text-align: right;
      background: none;
    }

    .chat-header {
      h3 {
        padding: 10px 20px;
        text-transform: uppercase;
        margin-left: 0;
        border-bottom: 1px solid #cccccc;

        @media screen and (max-width: 768px) {
          display: block;
        }
      }
    }

    .messages {
      height: calc(100% - 120px);

      .message-container {
        .message-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          width: 80%;

          .user-info {
            margin-right: 5px;
            @include avatar-text;

            img {
              width: 40px;
              max-height: 40px;
            }
          }

          .message {
            width: 100%;
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
          }
        }

        &.guest {
          display: flex;
          justify-content: flex-end;

          .message-wrap {
            flex-direction: row-reverse;

            .user-info {
              margin-left: 5px;
            }

            .message {
              background: rgba(204, 204, 204, 0.4);
            }
          }
        }
      }
    }

    .send {
      width: 90%;

      textarea {
        width: 100%;
        float: left;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 20px;
        height: 27px;
        margin: 0;
        background: none;
        font-family: "Heebo", sans-serif !important;
        font-weight: 300;
        color: #fff;
        margin-top: 5px;

        @media screen and (max-width: 768px) {
          color: #000;
        }
      }

      .send-button {
        cursor: pointer;
        position: absolute;
        right: 5px;
        color: var(--color-secondary);
        top: 4px;

        i {
          font-size: 22px;
        }
      }
    }
  }

  .right-top-bar {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: auto;
    border-radius: 0;
    justify-content: center;
    background: none;
    height: 35px;
    z-index: 1;

    .action-icons {
      bottom: 0;
      position: relative;
      padding: 0;

      .badge {
        position: absolute;
        top: 0;
        right: 0;
        width: 14px;
        height: 14px;
        background: var(--color-secondary);
        border-radius: 50%;
        text-align: center;
        color: #ffffff;
        padding: 0;
        line-height: 16px;
      }

      .active {
        background: var(--color-secondary);

        .badge {
          background: #ffffff;
          color: var(--color-secondary);
        }

        i {
          color: #fff;
        }
      }
    }
  }

  #conference-chats-list {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    right: -350px;
    top: 80px;
    width: 350px;
    height: 65vh;
    z-index: 102;
    transition: 0.3s ease all;
    border-radius: 8px;

    @media screen and (max-width: 768px) {
      top: 0;
    }

    .chat-header {
      padding: 20px 20px 0;

      h3 {
        color: #fff;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
      }
    }

    &.open {
      right: 80px;
      @media screen and (max-width: 768px) {
        top: 0;
        right: 0;
      }
    }

    .chat-filter {
      padding: 5px 20px 0;

      .input-group {
        border-radius: 7px;
        height: 40px;
        margin-top: 5px;
        margin-bottom: 10px;

        input {
          height: 40px;
          border-radius: 7px 0 0 7px;
          background: rgba(248, 248, 248, 0.5);
          color: white;
          margin-top: 0;
          font-family: "Heebo", sans-serif;

          &::placeholder {
            font-family: "Heebo", sans-serif;
          }
        }

        .input-group-text {
          background: var(--color-secondary);
          color: #fff;
          border: 0;
          border-radius: 0 7px 7px 0;
          width: 40px;
          justify-content: center;
          height: 40px;
        }
      }
    }

    .chats-list {
      height: calc(100% - 19vh);
      overflow: auto;
      color: #fff;

      li {
        margin-top: 10px;
        padding: 10px 20px;
        cursor: pointer;
        display: flex;

        .user-avatar {
          width: 48px;
          margin-right: 10px;
          position: relative;

          .badge {
            position: absolute;
            top: 0;
            left: 0;
            width: 14px;
            height: 14px;
            background: var(--color-secondary);
            border-radius: 50%;
            text-align: center;
            color: #ffffff;
            padding: 0;
            line-height: 16px;
          }

          img {
            width: 100%;
          }
        }

        &:hover {
          background-color: rgba(var(--color-secondary), 0.4);
        }

        .chat-item {
          display: flex;
          justify-content: space-between;

          .chat-data {
            .user-name {
              font-weight: bold;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

#terms-wrapper {
  iframe.pdf-frame {
    height: 100%;
  }
}
