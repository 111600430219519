#tag-popup:not(.trivia-lock), #vsimage-popup, #vsaction-popup {
  background: #fff;
  max-width: 100vw !important;
  max-height: 100vh !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;

  &.iframe {
    background: transparent;
  }

  &.tags.video {
    background: black;
  }

  &.game-popup {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: rgba(0, 0, 0, 0.3);

    &.finish-desktop {
      position: absolute;
      left: inherit;
      bottom: inherit;
      right: 0;
      top: 400px;
    }

    .game-tag-action-text {
      color: white;
      overflow: hidden;
      max-height: 110px;
      padding: 0 10px;
      .tag-title {
        padding: 10px 0;
        font-size: 24px;
        margin: 0;
      }
      .primary-btn {
        width: 60%;
      }
    }
    .game-lock-action-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      overflow: hidden;
      max-height: 200px;
      padding: 15px;
      .game-lock-input {
        margin: 0 10px 10px 10px;
        background: black;
        border-radius: 5px;
        color: white;
        font-family: Montserrat;
        font-size: 20px;
        &:focus {
          outline-color: black;
        }
      }
      .primary-btn {
        width: 60%;
      }
      .rtl {
        text-align: right;
        direction: rtl;
      }
    }
  }
  &.video-chat-popup {
    background: rgba(0, 0, 0, 0.3);
    width: 300px;
    height: 270px;
    text-align: center;

    .video-chat-tag-action-text {
      color: white;
      overflow: hidden;
      max-height: 270px;
      padding: 0 10px;
      .tag-title {
        padding: 10px 0;
        font-size: 24px;
        margin: 0;
      }
      .tag-description {
        text-align: start;
        font-size: 16px;
      }
      .tag-guests-count {
        font-size: 30px;
      }
      .primary-btn {
        width: 60%;
      }
    }
  }

  .tag-action-image {
    width: 100%;
    height: 100%;
    .image-title {
      width: 100%;
      top: 0;
      position: absolute;
      left: 0;
      text-align: center;
      padding: 5px;
      font-size: 12px;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .isFullScreen {
    top: 68px;
    right: 18px;
  }

  .tag-action-text {
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    text-align: center;

    &.justify-align {
      text-align: justify;
      pre {
        white-space: break-spaces;
        height: 100%;
      }
    }
  }

  .gallery-title {
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    z-index: 1;
    color: white;
    padding: 8px 16px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 7px 7px 0 0;
    h3 {
      text-align: start;
      font-weight: 200;
      margin-bottom: 0;
      font-size: 24px;
    }
  }

  #tag-gallery-fullscreen {
    position: relative;
    border-radius: 0 0 7px 7px;
    overflow: hidden;

    .gallery-link {
      width: 200px;
      height: auto;
      position: absolute;
      bottom: 16px;
      left: 16px;
      z-index: 1;
      color: white;
      padding: 8px 16px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 7px;

      a {
        text-align: start;
        font-weight: 200;
        margin-bottom: 0;
        font-size: 14px;
        color: inherit;
      }
    }
    .tag-gallery-slider {
      height: 100%;
      .slick-list {
        height: 100%;
        overflow-x: hidden;
      }
      .slick-track {
        height: 100%;
      }
      .slick-slide > div {
        height: 100%;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.9);
      }

      .slick-prev, .slick-next {
        position: absolute;
        top: 50%;
        z-index: 1;
        background-color: var(--color-secondary);
        opacity: 0.7;
        padding: 8px;
        width: auto;
        height: 80px;
        color: white;

        &:before {
          font-size: 24px;
          opacity: 1;
        }
        &.slick-disabled:before {
          opacity: .25;
        }
      }
      .slick-prev {
        left: 0;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        background: rgba(0, 0, 0, 0.2);

        &:before {
          font-family: "FontAwesome";
          font-weight: 900;
          content: "\f053";
          -webkit-font-smoothing: antialiased;
        }
      }
      .slick-next {
        right: 0;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        background: rgba(0, 0, 0, 0.2);

        &:before {
          font-family: "FontAwesome";
          font-weight: 900;
          content: "\f054";
          -webkit-font-smoothing: antialiased;
        }
      }
      .slick-dots {
        left: 50%;
        margin-left: -150px;
        max-width: 300px;
        width: 100%;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        padding-bottom: 10px;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;

        li {
          &.slick-active button {
            &:before {
              font-size: 14px;
              line-height: 24px;
              width: 24px;
              height: 24px;
              color: white;
            }
            &:after {
              font-size: 0;
            }
          }

          button {
            opacity: 1;
            &:before {
              font-size: 14px;
              line-height: 24px;
              width: 24px;
              height: 24px;
              color: white;
              opacity: 1;
              transition: font-size 0.2s 0.2s;
            }
            &:after {
              font-family: 'slick';
              font-size: 10px;
              line-height: 24px;
              position: absolute;
              top: 0;
              left: 0;
              width: 24px;
              height: 24px;
              content: '\2022';
              text-align: center;
              opacity: 1;
              color: rgba(0, 0, 0, 0.57);
              -webkit-font-smoothing: antialiased;
              transition: font-size 0.4s;
              //z-index: 0;
            }
          }
        }
      }

      img.bg {
        height: 100%;
        object-fit: contain;
      }
      video.bg {
        margin-bottom: 32px;
        height: calc(100% - 32px);
        width: 100%;
      }
    }
  }

  h2 {
    font-size: 1.4em;
    padding: 20px 0;
    text-align: center;
  }

  input {
    border-bottom: 1px solid #ccc;
  }
  &.contact {
    padding: 0 20px;
    max-height: 350px;
  }
  iframe {
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.rtl {
    text-align: right;
    input {
      text-align: right;
    }
  }
}
