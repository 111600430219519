.close-button {
    position: absolute;
    top: 10px;
    right: 10px
  }

  .close-button .icon-close {
    font-size: 14px;
    text-shadow: none;
    color: #222
  }

  .close-button.close-button-light .icon-close {
    color: #fff
  }

  .close-button.close-button-shadow .icon-close {
    color: #fff;
    text-shadow: 1px 1px 2px #000
  }

  @font-face {
    font-family: mp-font;
    src: url(/fonts/mp-font.woff2) format("woff2"), url(/fonts/mp-font.woff) format("woff"), url(/fonts/mp-font.svg#mp-font) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block
  }

  .mp-font {
    font-family: mp-font !important;
    speak: never;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .icon-toggle-pencil:before {
    content: "\e90c"
  }

  .icon-notes:before {
    content: "\e901"
  }

  .icon-brush-outline:before {
    content: "\e902"
  }

  .icon-comment:before {
    content: "\e903"
  }

  .icon-reply:before {
    content: "\e904"
  }

  .icon-blur-outline:before {
    content: "\e909"
  }

  .icon-arrows:before {
    content: "\e972"
  }

  .icon-error:before {
    content: "\e971"
  }

  .icon-info:before {
    content: "\e96e"
  }

  .icon-revert:before {
    content: "\e970"
  }

  .icon-delete:before {
    content: "\e952"
  }

  .icon-settings:before {
    content: "\e94c"
  }

  .icon-list:before {
    content: "\e966"
  }

  .icon-question:before {
    content: "\e908"
  }

  .icon-blocked:before {
    content: "\e90d"
  }

  .icon-dpad-down:before {
    content: "\e90e"
  }

  .icon-dpad-left:before {
    content: "\e90f"
  }

  .icon-dpad-right:before {
    content: "\e910"
  }

  .icon-dpad-up:before {
    content: "\e911"
  }

  .icon-close:before {
    content: "\e912"
  }

  .icon-ext-link:before {
    content: "\e936"
  }

  .icon-pause:before {
    content: "\e938"
  }

  .icon-pin:before {
    content: "\e939"
  }

  .icon-play-unicode:before {
    content: "\e93a"
  }

  .icon-fullscreen2:before {
    content: "\e91b"
  }

  .icon-user:before {
    content: "\e943"
  }

  .icon-minus:before {
    content: "\e94e"
  }

  .icon-zoom-reset:before {
    content: "\e94f"
  }

  .icon-360:before {
    content: "\e90b"
  }

  .icon-rotate:before {
    content: "\e93d"
  }

  .icon-photo-2d:before {
    content: "\e94a"
  }

  .icon-photo-360:before {
    content: "\e94b"
  }

  .icon-transitions:before {
    content: "\e973"
  }

  .icon-photo-add:before {
    content: "\e953"
  }

  .icon-hlr:before {
    content: "\e91c"
  }

  .icon-rotate-right:before {
    content: "\e961"
  }

  .icon-rotate-left:before {
    content: "\e962"
  }

  .icon-play:before {
    content: "\e93b"
  }

  .icon-start-location:before {
    content: "\e90a"
  }

  .icon-hlr-badge:before {
    content: "\e900"
  }

  .icon-eye-hide:before {
    content: "\e905"
  }

  .icon-eye-show:before {
    content: "\e906"
  }

  .icon-more-vert:before {
    content: "\e907"
  }

  .icon-mesh:before {
    content: "\e954"
  }

  .icon-vr:before {
    content: "\e944"
  }

  .icon-floor-controls-all:before {
    content: "\e914"
  }

  .icon-floor-controls:before {
    content: "\e915"
  }

  .icon-panorama:before {
    content: "\e916"
  }

  .icon-dollhouse:before {
    content: "\e917"
  }

  .icon-floorplan:before {
    content: "\e918"
  }

  .icon-fullscreen-exit:before {
    content: "\e919"
  }

  .icon-fullscreen:before {
    content: "\e91a"
  }

  .icon-share:before {
    content: "\e93e"
  }

  .icon-link:before {
    content: "\e91d"
  }

  .icon-email:before {
    content: "\e91e"
  }

  .icon-facebook:before {
    content: "\e91f"
  }

  .icon-linkedin:before {
    content: "\e920"
  }

  .icon-pinterest:before {
    content: "\e921"
  }

  .icon-twitter:before {
    content: "\e922"
  }

  .icon-media-mix:before {
    content: "\e946"
  }

  .icon-stem-height:before {
    content: "\e951"
  }

  .icon-toolbar-360-view:before {
    content: "\e956"
  }

  .icon-toolbar-edit-vr:before {
    content: "\e957"
  }

  .icon-toolbar-hlr:before {
    content: "\e958"
  }

  .icon-toolbar-labels:before {
    content: "\e959"
  }

  .icon-toolbar-mattertags:before {
    content: "\e95a"
  }

  .icon-toolbar-measurements:before {
    content: "\e95b"
  }

  .icon-toolbar-more-horiz:before {
    content: "\e95c"
  }

  .icon-toolbar-photos:before {
    content: "\e95d"
  }

  .icon-toolbar-scan-mgmt:before {
    content: "\e95e"
  }

  .icon-toolbar-start-location:before {
    content: "\e95f"
  }

  .icon-checkmark:before {
    content: "\e968"
  }

  .icon-back:before {
    content: "\e965"
  }

  .icon-plus:before {
    content: "\e967"
  }

  .icon-help:before {
    content: "\e96d"
  }

  .icon-escape-key:before {
    content: "\e96a"
  }

  .icon-draw-lines:before {
    content: "\e96b"
  }

  .icon-drag-edit:before {
    content: "\e96c"
  }

  .icon-finger-drag:before {
    content: "\e97b"
  }

  .icon-press-hold:before {
    content: "\e97c"
  }

  .icon-tape-measure:before {
    content: "\e964"
  }

  #page-root {
    [class*=" icon-"],
    [class^=icon-] {
      font-family: mp-font, serif !important;
      speak: never;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale
    }

    [class*=" icon-"] > :not([class^=path]),
    [class^=icon-] > :not([class^=path]) {
      font-family: Roboto, sans-serif
    }
  }

  .align-center {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  .align-vcenter {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }


  .button {
    position: relative;
    font-weight: 700;
    text-align: center;
    letter-spacing: .2px;
    color: #f5f4f3;
    transition: all .4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    pointer-events: auto;
    line-height: 23px;
    padding: 8px 18px;
    background-color: #222;
    font-family: IBM Plex Sans;
    font-size: 16px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden
  }

  .button:not(.button-small) {
    height: 39px;
    min-width: 100px
  }

  .button.button-small {
    height: 36px;
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 1.5px
  }

  .button.button-large {
    padding: 7.5px 25px;
    height: 40px;
    line-height: 25px;
    letter-spacing: 2px
  }

  @media (max-width: 576px) {
    .button.button-large {
      padding-left: 15px;
      padding-right: 15px
    }
  }

  .button.button-clear {
    background-color: transparent;
    color: #fff
  }

  .button.button-toolbar {
    height: 55px;
    line-height: 40px;
    background-color: #fff;
    box-shadow: inset 1px 0 0 #dadada;
    color: #222;
    font-size: 14px;
    letter-spacing: 2px
  }

  @media (max-width: 576px) {
    .button.button-toolbar {
      font-size: 12px
    }
  }

  .button.button-toolbar.button-dimmed,
  .button.button-toolbar.button-disabled {
    background-color: hsla(0, 0%, 100%, .6);
    color: rgba(0, 0, 0, .3)
  }

  .button.button-light {
    background-color: #f5f4f3;
    color: #222
  }

  .button.button-light.button-dimmed,
  .button.button-light.button-disabled {
    background-color: rgba(245, 244, 243, .3);
    color: rgba(0, 0, 0, .3)
  }

  .button.button-secondary {
    height: 39px;
    line-height: 19px;
    border: 2px solid #222;
    color: #222;
    background-color: #fff
  }

  .button.button-secondary.button-light {
    border-color: #f5f4f3;
    color: #f5f4f3;
    background-color: transparent
  }

  .button.button-secondary.button-large {
    height: 40px;
    line-height: 23px
  }

  .button.button-secondary + .button {
    margin-left: 20px
  }

  .button.button-active,
  .button.button-busy {
    background-color: #ff3158;
    color: #fff
  }

  .button.button-inline {
    display: inline-block
  }

  .button.button-dimmed,
  .button.button-disabled {
    background-color: rgba(0, 0, 0, .3)
  }

  .button.button-overlay {
    background-color: #fff;
    color: #222;
    border-radius: 30px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .3)
  }

  .button.button-overlay.button-active,
  .button.button-overlay.button-busy {
    background-color: #ff3158;
    color: #fff
  }

  .button.button-overlay.button-dimmed,
  .button.button-overlay.button-disabled {
    background-color: hsla(0, 0%, 100%, .6);
    color: rgba(0, 0, 0, .5);
    box-shadow: none
  }

  .button.button-with-icon {
    display: flex;
    align-items: center;
    font-size: 14px
  }

  .button.button-with-icon.button-inline {
    display: inline-flex
  }

  .button.button-with-icon:not(.button-toolbar).button-large {
    font-size: 16px
  }

  .button.button-with-icon .icon {
    margin-right: 6px;
    font-size: 18px
  }

  .button.button-busy,
  .button.button-decorative,
  .button.button-disabled {
    pointer-events: none;
    cursor: default
  }

  .no-touch .button:not(.button-dimmed):hover {
    background-color: #ff3158;
    color: #fff
  }

  .no-touch .button:not(.button-dimmed).button-clear:hover {
    background-color: transparent;
    color: #ff3158
  }

  .no-touch .button:not(.button-dimmed).button-toolbar:hover {
    background-color: #fff;
    color: #ff3158
  }

  .no-touch .button:not(.button-dimmed).button-secondary:hover {
    background: #fff;
    color: #ff3158;
    border-color: #ff3158
  }

  .no-touch .button:not(.button-dimmed).button-overlay:hover {
    background-color: #ff3158;
    color: #fff
  }

  .no-touch .button.button-dimmed.button-overlay:hover {
    background-color: hsla(0, 0%, 100%, .6);
    color: rgba(0, 0, 0, .5)
  }

  .icon-button {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    background-color: transparent;
    font-weight: 700;
    text-align: center;
    letter-spacing: .2px;
    transition-property: opacity, color, background-color;
    transition-duration: .5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    pointer-events: auto
  }

  .icon-button .icon {
    padding: 10px;
    line-height: 35px;
    color: #f5f4f3;
    font-family: mp-font;
    text-shadow: 1px 1px 2px #000;
    font-size: 22px
  }

  .icon-button.icon-button-plain {
    padding: 0;
    border-radius: 0
  }

  .icon-button.icon-button-plain .icon {
    color: #222;
    font-size: 14px;
    line-height: 41px;
    text-shadow: none
  }

  .icon-button.icon-button-plain.button-dimmed .icon {
    color: #bdbdbd
  }

  .icon-button.icon-button-toolbar {
    height: 55px;
    min-width: 44px;
    background-color: #fff;
    border-radius: 0;
    box-shadow: inset 1px 0 0 #dadada
  }

  .icon-button.icon-button-toolbar .icon {
    color: #222;
    line-height: 55px;
    text-shadow: none
  }

  .icon-button.icon-button-toolbar .icon.icon-help {
    font-size: 16px
  }

  .icon-button.icon-button-toolbar .icon.icon-revert {
    font-size: 24px
  }

  .icon-button.icon-button-overlay {
    height: 31px;
    width: 31px;
    margin: 5px;
    padding: 0;
    background-color: rgba(0, 0, 0, .5);
    letter-spacing: 0;
    vertical-align: top;
    text-transform: none
  }

  .icon-button.icon-button-overlay .icon {
    padding: 0;
    color: #fff;
    font-size: 15px;
    text-shadow: none
  }

  .icon-button.icon-button-overlay.button-dimmed,
  .icon-button.icon-button-overlay.button-disabled {
    background-color: rgba(0, 0, 0, .2)
  }

  .icon-button.icon-button-overlay.button-dimmed .icon,
  .icon-button.icon-button-overlay.button-disabled .icon {
    color: hsla(0, 0%, 100%, .3)
  }

  .icon-button.icon-button-overlay > span {
    line-height: 31px
  }

  .icon-button.icon-button-overlay.button-active,
  .icon-button.icon-button-overlay.button-busy {
    background-color: #222
  }

  .icon-button.icon-button-overlay.button-active .icon,
  .icon-button.icon-button-overlay.button-busy .icon {
    color: #ff3158
  }

  .icon-button.icon-button-primary {
    height: 40px;
    width: 40px;
    font-size: 18px;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .3)
  }

  .icon-button.icon-button-primary .icon {
    color: #222;
    text-shadow: none
  }

  .icon-button.icon-button-primary.button-active,
  .icon-button.icon-button-primary.button-busy {
    background-color: #ff3158
  }

  .icon-button.icon-button-primary.button-active .icon,
  .icon-button.icon-button-primary.button-busy .icon {
    color: #fff
  }

  .icon-button.icon-button-primary.button-dimmed,
  .icon-button.icon-button-primary.button-disabled {
    background-color: hsla(0, 0%, 100%, .6);
    box-shadow: none
  }

  .icon-button.icon-button-primary.button-dimmed .icon,
  .icon-button.icon-button-primary.button-disabled .icon {
    color: rgba(0, 0, 0, .5)
  }

  .icon-button.button-disabled {
    opacity: .5
  }

  .icon-button.button-active .icon,
  .icon-button.button-busy .icon {
    color: #ff3158;
    text-shadow: none
  }

  .icon-button.button-interactive {
    position: absolute;
    top: 0;
    right: 0;

    &.button-help-terms {
      top: 15px;
      right: 10px;
    }

    .icon {
      font-size: 16px;
    }
  }

  .icon-button:not(.button-interactive) {
    cursor: default;
    pointer-events: none
  }

  .icon-button.button-interactive:not(.button-dimmed):active .icon {
    color: #ff3158;
    text-shadow: none
  }

  .icon-button.button-interactive:not(.button-dimmed):active.icon-button-overlay {
    background-color: #222
  }

  .icon-button.button-interactive:not(.button-dimmed):active.icon-button-primary {
    background-color: #ff3158
  }

  .icon-button.button-interactive:not(.button-dimmed):active.icon-button-primary .icon {
    color: #fff
  }

  .icon-button-social {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 0;
    text-align: center;
    color: #222;
    background: #f5f4f3;
    border-radius: 100%;
    cursor: pointer
  }

  .icon-button-social .icon {
    color: inherit;
    text-shadow: none;
    font-size: 24px
  }

  .no-touch .icon-button:not(.button-disabled):not(.button-dimmed):hover .icon {
    color: #ff3158;
    text-shadow: none
  }

  .no-touch .icon-button:not(.button-disabled):not(.button-dimmed):hover.icon-button-overlay {
    background-color: #222
  }

  .no-touch .icon-button:not(.button-disabled):not(.button-dimmed):hover.icon-button-primary {
    background-color: #ff3158
  }

  .no-touch .icon-button:not(.button-disabled):not(.button-dimmed):hover.icon-button-primary .icon {
    color: #fff
  }

  #page-root {
    [data-balloon] {
      position: relative
    }

    [data-balloon]:after,
    [data-balloon]:before {
      filter: alpha(opacity=0);
      -khtml-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity .05s ease-out .05s;
      bottom: 100%;
      left: 50%;
      position: absolute;
      z-index: 75;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
      -webkit-transform-origin: top;
      transform-origin: top;
      line-height: normal
    }

    [data-balloon]:before {
      content: "";
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(0, 0, 0, 0.75)' d='M2.658 0h32.004c-6 0-11.627 12.002-16.002 12.002C14.285 12.002 8.594 0 2.658 0z'/%3E%3C/svg%3E") no-repeat;
      background-size: 100% auto;
      height: 6px;
      width: 18px;
      margin-bottom: 5px
    }

    [data-balloon]:after {
      content: attr(data-balloon);
      padding: 6px;
      margin-bottom: 11px;
      background: rgba(0, 0, 0, .75);
      border-radius: 4px;
      color: #fff;
      font-family: Roboto;
      font-weight: 500;
      font-size: 11px;
      letter-spacing: .2px;
      white-space: nowrap
    }

    [data-balloon][data-balloon-size=xs]:after {
      font-size: 10px;
      padding: .3em
    }

    [data-balloon]:hover:not(.disabled):not(.tooltip-hidden):after,
    [data-balloon]:hover:not(.disabled):not(.tooltip-hidden):before {
      filter: alpha(opacity=100);
      -khtml-opacity: 1;
      -moz-opacity: 1;
      opacity: 1
    }

    [data-balloon][data-balloon-pos=down-left]:before,
    [data-balloon][data-balloon-pos=down-right]:before,
    [data-balloon][data-balloon-pos=down]:before {
      bottom: auto;
      left: 50%;
      top: 100%;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(0, 0, 0, 0.75)' d='M33.342 12H1.338c6 0 11.627-12.002 16.002-12.002C21.715-.002 27.406 12 33.342 12z'/%3E%3C/svg%3E") no-repeat;
      background-size: 100% auto;
      height: 6px;
      width: 18px;
      margin-top: 2px;
      margin-bottom: 0
    }

    [data-balloon][data-balloon-pos=down-left]:after,
    [data-balloon][data-balloon-pos=down-right]:after,
    [data-balloon][data-balloon-pos=down]:after {
      margin-top: 8px;
      bottom: auto;
      top: 100%
    }

    [data-balloon][data-balloon-pos=down]:after {
      left: 50%;
      -webkit-transform: translate(-50%);
      transform: translate(-50%)
    }

    [data-balloon][data-balloon-pos=down-left]:after {
      left: 20px;
      -webkit-transform: translate(-100%);
      transform: translate(-100%)
    }

    [data-balloon][data-balloon-pos=down-right]:after {
      left: calc(50% - 14px);
      -webkit-transform: translate(0);
      transform: translate(0)
    }

    [data-balloon][data-balloon-pos=left]:after,
    [data-balloon][data-balloon-pos=left]:before {
      bottom: auto;
      left: auto;
      right: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%)
    }

    [data-balloon][data-balloon-pos=left]:after {
      margin-right: 11px
    }

    [data-balloon][data-balloon-pos=left]:before {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(0, 0, 0, 0.75)' d='M0 33.342V1.338c0 6 12.002 11.627 12.002 16.002C12.002 21.715 0 27.406 0 33.342z'/%3E%3C/svg%3E") no-repeat;
      background-size: 100% auto;
      height: 18px;
      width: 6px;
      margin-right: 5px;
      margin-bottom: 0
    }

    [data-balloon][data-balloon-pos=right]:after,
    [data-balloon][data-balloon-pos=right]:before {
      bottom: auto;
      left: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%)
    }

    [data-balloon][data-balloon-pos=right]:after {
      margin-left: 11px
    }

    [data-balloon][data-balloon-pos=right]:before {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(0, 0, 0, 0.75)' d='M12 2.658v32.004c0-6-12.002-11.627-12.002-16.002C-.002 14.285 12 8.594 12 2.658z'/%3E%3C/svg%3E") no-repeat;
      background-size: 100% auto;
      height: 18px;
      width: 6px;
      margin-bottom: 0;
      margin-left: 5px
    }

    [data-balloon][data-balloon-pos=right]:hover:after,
    [data-balloon][data-balloon-pos=right]:hover:before {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%)
    }

    [data-balloon][data-balloon-pos=up-right]:after {
      left: 11px;
      -webkit-transform: none;
      transform: none
    }

    [data-balloon][data-balloon-pos=up-left]:after {
      left: auto;
      right: 0;
      -webkit-transform: none;
      transform: none
    }

    [data-balloon][data-balloon-width]:after {
      white-space: normal
    }

    [data-balloon][data-balloon-width][data-balloon-width=small]:after {
      width: 80px
    }

    [data-balloon][data-balloon-width][data-balloon-width=medium]:after {
      width: 135px
    }

    [data-balloon][data-balloon-width][data-balloon-width=large]:after {
      width: 260px
    }

    [data-balloon][data-balloon-width][data-balloon-width=xlarge]:after {
      width: 90vw
    }

    @media screen and (min-width: 768px) {
      [data-balloon][data-balloon-width][data-balloon-width=xlarge]:after {
        width: 380px
      }
    }

    [data-balloon][data-balloon-width][data-balloon-width=fit]:after {
      width: 100%
    }

    [data-balloon][data-balloon-pre]:after {
      white-space: pre
    }

    [data-balloon].tooltip-hidden:after,
    [data-balloon].tooltip-hidden:before {
      opacity: 0
    }

    button[data-balloon] {
      overflow: visible
    }
  }

  .mobile [data-balloon]:after,
  .mobile [data-balloon]:before {
    opacity: 0
  }


  button.icon {
    padding: 0;
    background: inherit;
    border: none;
    outline: inherit;
    font-size: inherit
  }

  button.icon:not(.disabled) {
    pointer-events: auto;
    cursor: pointer
  }

  button.icon:not(.disabled):hover,
  button.icon:not(.disabled):hover:before {
    color: #ff3158
  }

  .tour-container {
    .outerScrollbarWrapper {
      position: relative;
      overflow: hidden;
      width: calc(100% + 27px);
      height: calc(100% + 27px);
      margin-right: -27px
    }

    .outerScrollbarWrapper.button-scrolling.vertical .innerScrollbarWrapper {
      top: 40px;
      bottom: 40px
    }

    .outerScrollbarWrapper.button-scrolling.horizontal .innerScrollbarWrapper {
      left: 40px;
      right: 40px
    }

    .outerScrollbarWrapper .innerScrollbarWrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      touch-action: manipulation
    }

    .outerScrollbarWrapper .innerScrollbarWrapper .paddingScrollbarWrapper {
      position: absolute;
      padding-right: 27px;
      padding-bottom: 27px;
      box-sizing: content-box
    }

    .outerScrollbarWrapper .scrollbarTrack {
      position: absolute;
      bottom: 27px;
      right: 27px;
      background-color: transparent;
      border-radius: 5px
    }

    .outerScrollbarWrapper .scrollbarTrack .scrollbarThumb {
      position: relative;
      transition: opacity .25s ease-in-out;
      opacity: 0;
      background-color: rgba(181, 180, 179, .5);
      border-radius: 5px
    }

    .outerScrollbarWrapper .scrollbarTrack.horizontal {
      left: 0;
      height: 8px;
      margin-right: 8px;
      margin-left: 8px
    }

    .outerScrollbarWrapper .scrollbarTrack.horizontal .scrollbarThumb {
      height: 4px
    }

    .outerScrollbarWrapper .scrollbarTrack.vertical {
      top: 0;
      width: 8px;
      margin-top: 8px;
      margin-bottom: 8px
    }

    .outerScrollbarWrapper .scrollbarTrack.vertical .scrollbarThumb {
      width: 4px
    }

    .outerScrollbarWrapper.dual-scroll {
      overflow: scroll
    }

    .outerScrollbarWrapper.vertical,
    .outerScrollbarWrapper.verticalOnly {
      height: 100%
    }

    .outerScrollbarWrapper.vertical .scroll-forward,
    .outerScrollbarWrapper.verticalOnly .scroll-forward {
      bottom: 0
    }

    .outerScrollbarWrapper.vertical .innerScrollbarWrapper,
    .outerScrollbarWrapper.verticalOnly .innerScrollbarWrapper {
      overflow-x: hidden;
      overflow-y: scroll
    }

    .outerScrollbarWrapper.vertical .innerScrollbarWrapper .paddingScrollbarWrapper,
    .outerScrollbarWrapper.verticalOnly .innerScrollbarWrapper .paddingScrollbarWrapper {
      padding-bottom: 0
    }

    .outerScrollbarWrapper.horizontal,
    .outerScrollbarWrapper.horizontalOnly {
      width: 100%
    }

    .outerScrollbarWrapper.horizontal .scroll-forward,
    .outerScrollbarWrapper.horizontalOnly .scroll-forward {
      right: 0
    }

    .outerScrollbarWrapper.horizontal .innerScrollbarWrapper,
    .outerScrollbarWrapper.horizontalOnly .innerScrollbarWrapper {
      overflow-x: scroll;
      overflow-y: hidden
    }

    .outerScrollbarWrapper.horizontal .innerScrollbarWrapper .paddingScrollbarWrapper,
    .outerScrollbarWrapper.horizontalOnly .innerScrollbarWrapper .paddingScrollbarWrapper {
      padding-right: 0
    }

    .outerScrollbarWrapper .scroll-forward {
      position: absolute
    }

    div:active > .outerScrollbarWrapper .scrollbarTrack.horizontal .scrollbarThumb,
    div:active > .outerScrollbarWrapper .scrollbarTrack.vertical .scrollbarThumb,
    div:active > div > .outerScrollbarWrapper .scrollbarTrack.horizontal .scrollbarThumb,
    div:active > div > .outerScrollbarWrapper .scrollbarTrack.vertical .scrollbarThumb,
    div:hover > .outerScrollbarWrapper .scrollbarTrack.horizontal .scrollbarThumb,
    div:hover > .outerScrollbarWrapper .scrollbarTrack.vertical .scrollbarThumb,
    div:hover > div > .outerScrollbarWrapper .scrollbarTrack.horizontal .scrollbarThumb,
    div:hover > div > .outerScrollbarWrapper .scrollbarTrack.vertical .scrollbarThumb {
      opacity: 1
    }

    #reel.sorting .scrollbarTrack {
      visibility: hidden
    }

    #pause-button {
      opacity: 1;
      transition: opacity .5s;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      pointer-events: none;
      cursor: pointer
    }

    #pause-button.faded-out {
      opacity: 0;
      pointer-events: none
    }

    #pause-button.faded-out * {
      pointer-events: none !important
    }

    #pause-button img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) scale(2);
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
      transition: all .7s
    }

    #pause-button img.justPaused {
      opacity: 1;
      transition: all .7s;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1)
    }

    .tour-playing #pause-button {
      pointer-events: auto
    }

    .overlay-ui {
      width: 100%;
      height: 100%
    }

    .overlay-layer,
    .overlay-ui {
      position: absolute;
      top: 0;
      left: 0
    }

    .overlay-layer {
      bottom: 0;
      right: 0;
      overflow: hidden
    }

    *,
    :after,
    :before {
      box-sizing: border-box
    }

    select,
    textarea {
      font-family: Roboto;
      letter-spacing: .2px
    }

    canvas {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none
    }

    p {
      letter-spacing: .2px
    }

    input {
      font-family: Roboto
    }

    input:focus {
      outline-color: #ff3158
    }

    .hidden {
      display: none !important
    }

    .invisible {
      visibility: hidden
    }

    ::-moz-selection {
      background: rgba(255, 49, 88, .1)
    }

    ::selection {
      background: rgba(255, 49, 88, .1)
    }

    .link,
    .link-btn {
      cursor: pointer;
      color: inherit;
      letter-spacing: .2px;
      pointer-events: auto;
      transition: color .25s
    }

    .link-btn.link,
    .link.link {
      white-space: nowrap;
      text-decoration: underline;
      font-weight: 500
    }

    .link-btn.disabled,
    .link.disabled {
      opacity: .3;
      pointer-events: none;
      cursor: default
    }

    .link-btn.active,
    .link.active {
      color: #ff3158;
      pointer-events: none;
      text-decoration: none;
      cursor: default
    }

    .link-btn:hover,
    .link:hover {
      color: #ff3158
    }

    .clear-floats:after {
      content: "";
      display: table;
      clear: both
    }

    .strong {
      font-weight: 700
    }

    .note {
      font-size: .8em;
      font-weight: 500;
      color: #777
    }


    #terms-modal #terms-wrapper {
      position: absolute;
      top: 50px;
      left: 50px;
      right: 25px;
      bottom: 50px;
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none
    }

    #terms-modal #terms-wrapper #terms-text {
      padding-right: 25px
    }

    #terms-modal.modal {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0;
      margin: 0;
      height: 80vh;
      max-width: 95%;
      max-height: 95%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      overflow: hidden;
      overflow-y: scroll;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
      border: 0;
      pointer-events: auto;
      color: #fff;
      z-index: 111;
      background: rgba(0, 0, 0, 0.5);
    }

    #help-modal .navigation {
      display: table;
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -55%);
      transform: translate(-50%, -55%)
    }

    @media only screen and (max-height: 600px) and (max-width: 700px) {
      #help-modal .navigation {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
      }
    }

    #help-modal .navigation.small {
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%)
    }

    #help-modal .navigation .row {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #help-modal .navigation .row.topInfo {
      font-size: 16px;
      letter-spacing: .2px
    }

    #help-modal .navigation .row.topInfo .column {
      padding: 6vh 4vw
    }

    #help-modal .navigation .row.bigImage img {
      vertical-align: bottom;
      width: calc(3.2vh + 3.2vw);
      margin-bottom: 15px
    }

    #help-modal .navigation .row.bigImage img[src="/images/nav_help_mouse_click.png"],
    #help-modal .navigation .row.bigImage img[src="/images/nav_help_mouse_position_right.png"] {
      width: calc(3.936vh + 3.936vw)
    }

    #help-modal .navigation .row.bigImage img[src="/images/nav_help_mouse_position_left.png"] {
      width: calc(3.744vh + 3.744vw)
    }

    #help-modal .navigation .row.hr .column {
      padding: 3vh
    }

    #help-modal .navigation .row.hr .column hr {
      width: 6vh
    }

    #help-modal .navigation .row.hr .column.noDivider {
      visibility: hidden
    }

    #help-modal .navigation .row.bottomInfo {
      font-size: 16px
    }

    #help-modal .navigation .row.bottomInfo .column {
      padding: 0 6vw 6vh
    }

    #help-modal .navigation .row.keyboard img {
      vertical-align: middle;
      width: 8.66vh
    }

    #help-modal .navigation .row.keyboard img[src="/images/nav_help_inside_key.png"],
    #help-modal .navigation .row.keyboard img[src="/images/nav_help_keyboard_up_down.png"] {
      width: 4.33vh
    }

    #help-modal .navigation .row.keyboard img[src="/images/nav_help_keyboard_all.png"] {
      width: 12.99vh
    }

    @media only screen and (max-height: 600px) and (max-width: 700px) {
      #help-modal .navigation .row.keyboard img {
        width: 10vh
      }
      #help-modal .navigation .row.keyboard img[src="/images/nav_help_inside_key.png"],
      #help-modal .navigation .row.keyboard img[src="/images/nav_help_keyboard_up_down.png"] {
        width: 5vh
      }
      #help-modal .navigation .row.keyboard img[src="/images/nav_help_keyboard_all.png"] {
        width: 15vh
      }
    }

    #help-modal .navigation .row .column {
      display: table-cell;
      width: 30%
    }

    @media only screen and (max-height: 600px) and (max-width: 700px) {
      #help-modal .navigation .row.bottomInfo,
      #help-modal .navigation .row.topInfo {
        font-size: 2vw
      }
      #help-modal .navigation .row.bottomInfo .column,
      #help-modal .navigation .row.topInfo .column {
        padding: 0 2vw 4vh
      }
    }

    @media only screen and (max-height: 600px) and (max-width: 700px) {
      #help-modal .navigation.small .row {
        font-size: 1.9vw
      }
      #help-modal .navigation.small .row .column {
        padding: 0 1.5vw 3vh
      }
    }

    @media only screen and (orientation: landscape) {
      #help-modal.mobile .navigation {
        width: 90%
      }
      #help-modal.mobile .navigation .row.topInfo {
        font-size: 1.9vw
      }
      #help-modal.mobile .navigation .row.topInfo .column {
        padding: 2vw 4vw
      }
      #help-modal.mobile .navigation .row.bigImage img {
        width: 6.3vw
      }
      #help-modal.mobile .navigation .row.bigImage img[src="/images/nav_help_gesture_drag.png"] {
        width: 5.87511628vw
      }
      #help-modal.mobile .navigation .row.bigImage img[src="/images/nav_help_gesture_tap.png"] {
        width: 8.20465116vw
      }
      #help-modal.mobile .navigation .row.bigImage img[src="/images/nav_help_gesture.drag_two_finger.png"] {
        width: 5.42093023vw
      }
    }

    @media only screen and (orientation: portrait) {
      #help-modal.mobile .navigation {
        position: absolute;
        overflow: hidden;
        top: 55px;
        bottom: 20px;
        height: calc(100% - 75px);
        left: 0;
        right: 0;
        -webkit-transform: none;
        transform: none
      }
      #help-modal.mobile .navigation .nav-wrapper {
        display: flex;
        flex-direction: column;
        width: 68vw;
        max-width: 218px
      }
      #help-modal.mobile .navigation .nav-wrapper > div.row {
        font-size: 14px;
        margin: auto
      }
      #help-modal.mobile .navigation .nav-wrapper > div.row.topInfo {
        padding-top: 1.5em
      }
      #help-modal.mobile .navigation .nav-wrapper > div.row.bigImage {
        padding-top: 1em
      }
      #help-modal.mobile .navigation .nav-wrapper > div.row.bigImage img {
        width: 43px
      }
      #help-modal.mobile .navigation .nav-wrapper > div.row.bigImage img[src="/images/nav_help_gesture_drag.png"] {
        width: 40.1px
      }
      #help-modal.mobile .navigation .nav-wrapper > div.row.bigImage img[src="/images/nav_help_gesture_tap.png"] {
        width: 56px
      }
      #help-modal.mobile .navigation .nav-wrapper > div.row.bigImage img[src="/images/nav_help_gesture.drag_two_finger.png"] {
        width: 37px
      }
      #help-modal.mobile .navigation .paddingScrollbarWrapper {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
      }
    }

    @media screen and (min-height: 550px) and (orientation: portrait) {
      #help-modal.mobile .navigation .paddingScrollbarWrapper {
        top: 50%;
        -webkit-transform: translate(-50%, -55%);
        transform: translate(-50%, -55%)
      }
    }

    @media screen and (min-height: 650px) and (orientation: portrait) {
      #help-modal.mobile .navigation .nav-wrapper {
        max-width: 240px
      }
      #help-modal.mobile .navigation .nav-wrapper > div.row.topInfo {
        font-size: 16px;
        padding-top: 3em;
        padding-bottom: 1em
      }
      #help-modal.mobile .navigation .nav-wrapper > div.row.bigImage img {
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
      }
    }

    @media screen and (min-width: 650px) and (orientation: portrait) {
      #help-modal.mobile .navigation .nav-wrapper > div.row.topInfo {
        font-size: 16px
      }
    }

    #help-modal .more-help-wrapper {
      position: absolute;
      overflow: hidden;
      top: 55px;
      bottom: 20px;
      left: 0;
      right: 0
    }

    #help-modal .more-help-wrapper .more-help {
      display: table;
      padding: 3vw
    }

    #help-modal .more-help-wrapper .more-help .innerRow {
      display: table-row
    }

    #help-modal .more-help-wrapper .more-help .innerRow > div {
      display: table-cell;
      table-layout: fixed;
      min-width: 190px;
      line-height: 1.29;
      padding: 0 1.5vw;
      width: 20vw
    }

    #help-modal .more-help-wrapper .more-help .innerRow > div.topInfo {
      vertical-align: bottom;
      font-size: 1.45vw;
      font-weight: 500
    }

    @media screen and (max-width: 1100px) {
      #help-modal .more-help-wrapper .more-help .innerRow > div.topInfo {
        font-size: 16px
      }
    }

    #help-modal .more-help-wrapper .more-help .innerRow > div.bigImage {
      padding: 1.85vw 0;
      font-size: 2.5vw;
      position: relative
    }

    #help-modal .more-help-wrapper .more-help .innerRow > div.bigImage img {
      height: 2.5vw
    }

    #help-modal .more-help-wrapper .more-help .innerRow > div.bigImage .icon-vr {
      font-size: 2vw
    }

    @media screen and (max-width: 1100px) {
      #help-modal .more-help-wrapper .more-help .innerRow > div.bigImage {
        padding: 20px 0;
        font-size: 30px
      }
      #help-modal .more-help-wrapper .more-help .innerRow > div.bigImage .icon-vr {
        font-size: 24px
      }
      #help-modal .more-help-wrapper .more-help .innerRow > div.bigImage img {
        height: 30px
      }
    }

    #help-modal .more-help-wrapper .more-help .innerRow > div.bigImage.floors {
      position: relative;
      top: 5px
    }

    #help-modal .more-help-wrapper .more-help .innerRow > div.bigImage.floors span span {
      position: absolute;
      top: 28%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 1.2vw;
      font-weight: 700
    }

    @media screen and (max-width: 1100px) {
      #help-modal .more-help-wrapper .more-help .innerRow > div.bigImage.floors span span {
        font-size: 14px
      }
    }

    #help-modal .more-help-wrapper .more-help .innerRow > div.bottomInfo {
      vertical-align: top;
      font-size: 1.25vw
    }

    @media screen and (max-width: 1100px) {
      #help-modal .more-help-wrapper .more-help .innerRow > div.bottomInfo {
        font-size: 14px
      }
    }

    #help-modal .more-help-wrapper .more-help .rowPadding {
      height: 10vh;
      min-height: 50px
    }

    @media screen and (min-height: 550px) {
      #help-modal .more-help-wrapper.desktop .paddingScrollbarWrapper {
        top: 45%;
        -webkit-transform: translateY(-45%);
        transform: translateY(-45%)
      }
    }

    @media only screen and (orientation: portrait) {
      #help-modal .more-help-wrapper.desktop .rowPadding {
        height: 15vh
      }
    }

    #help-modal .more-help-wrapper.mobile .paddingScrollbarWrapper {
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
    }

    @media only screen and (orientation: portrait) {
      #help-modal .more-help-wrapper.mobile .more-help .innerRow > div {
        min-width: 40vw
      }
      #help-modal .more-help-wrapper.mobile .more-help .innerRow > div.topInfo {
        font-size: calc(8px + 1vh)
      }
      #help-modal .more-help-wrapper.mobile .more-help .innerRow > div.bottomInfo {
        font-size: calc(5px + 1vh)
      }
    }

    @media only screen and (orientation: landscape) {
      #help-modal .more-help-wrapper.mobile .more-help .innerRow > div {
        min-width: 27vw
      }
      #help-modal .more-help-wrapper.mobile .more-help .innerRow > div.topInfo {
        font-size: calc(8px + 1vw)
      }
      #help-modal .more-help-wrapper.mobile .more-help .innerRow > div.bottomInfo {
        font-size: calc(5px + 1vw)
      }
    }

    #help-modal {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .7);
      text-align: center;
      color: #dadada;
      z-index: 111;
      opacity: 0;
      transition: opacity .5s
    }

    #help-modal.faded-in {
      opacity: 1;
      pointer-events: auto
    }

    #help-modal .tabs {
      transition: all .3s;
      font-weight: 500
    }

    #help-modal .tabs div {
      padding: 16px 55px 15px 20px;
      display: inline-block;
      font-size: 18px
    }

    #help-modal .tabs div span {
      padding-bottom: 3px;
      border-bottom: 1px solid transparent;
      letter-spacing: .3px;
      transition: all .3s
    }

    #help-modal .tabs div.active span {
      border-bottom: 1px solid #fff
    }

    #help-modal .tabs div:hover {
      cursor: pointer;
      color: #ff3158
    }

    #help-modal .tabs div:hover.active span {
      border-bottom: 1px solid #ff3158
    }

    #help-modal.mobile .tabs div {
      padding: 16px 25px 15px 20px;
      font-size: 14px
    }
  }

