$mp-font: 'Matterport';
@font-face {
  font-family: Matterport;
  src: url('/fonts/mp-font.ttf');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

.mp-icon {
  font-family: Matterport !important;
}
.mp-dollhouse {
  font-family: Matterport !important;
  &:before {
    content: "\E90D";
  }
}
.mp-floorplan {
  font-family: Matterport !important;
  &:before {
    content: "\E90E";
  }
}
.mp-close {
  font-family: Matterport !important;
  &:before {
    content: "\E912";
  }
}
