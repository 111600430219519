@import "_reset.scss";
@import "_vars.scss";

$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-height: 2px;
$hamburger-layer-width: 20px;
$hamburger-layer-spacing: 4px;
$hamburger-active-layer-color: var(--color-secondary);
$hamburger-layer-color: #fff;

$hamburger-types: (spin);

@import "sass/hamburgers/_hamburgers.scss";
@import "MatterportFont.scss";

button:focus,
div[role="button"]:focus {
  outline-style: none;
  border: none;
}

.fb-customerchat iframe {
  margin: 0 !important;
}

#fb-root {
  iframe {
    left: inherit !important;
    transition: inherit !important;
  }
  iframe[data-testid="bubble_iframe"] {
    bottom: 104px !important;

    @media screen and (max-width: 768px) {
      bottom: 144px !important;
    }
  }
  iframe[data-testid="unread_iframe"] {
    right: 12px !important;
    bottom: 148px !important;
    left: inherit;
    transition: inherit;
    @media screen and (max-width: 768px) {
      bottom: 188px !important;
    }
  }
}

.react-switch-bg {
  height: 20px !important;
  width: 48px !important;
  div {
    height: 20px !important;
    width: 20px !important;
  }
}

.react-switch-handle {
  height: 15px !important;
  width: 15px !important;
  top: 2.5px !important;
}

.loading {
  height: 100%;
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  svg {
    @include superCenter(absolute);
  }
}

.hint-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn {
  background-color: transparent;
  margin: 0;
  padding: 0;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.lds-ripple {
  display: block;
  width: 60px;
  height: 60px;
  @include superCenter(absolute);
}
.lds-ripple div {
  position: absolute;
  border: 4px solid var(--color-primary);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

body,
html {
  font-family: var(--custom-font-family, 'Heebo', sans-serif);
  // Changed from 14px to 16px because 16 is the default font size in browsers
  // Also to have consistent MUI text size in Admin and Public (BM, for example)
  font-size: 16px;
  box-sizing: border-box;
  @include fullpage;
  font-weight: 300;
  // touch-action: pan-x pan-y;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

h1,
h2 {
  font-family: var(--custom-font-family, 'Heebo', sans-serif);
  font-style: italic;
  font-weight: 700;
}

p,div,button,label,input,select,option,h3,h4,h5,h6,a {
  font-family: var(--custom-font-family, 'Heebo', sans-serif)
}

#root,
#app-root {
  @include fullpage;
}

.loadingDiv {
  @include fullpage;
  background: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  line-height: 100vh;
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
}

input,
select,
textarea {
  border-radius: 2px;
  font-size: 1em;
  padding: 8px;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  text-indent: 8px;
  display: block;
  font-family: "Heebo", sans-serif;
  font-display: swap;
}

.inner-window {
  float: right;
  width: calc(100% - 180px);
  background: #f8f8f8;
  height: calc(100vh - 55px);
  position: relative;
}
.primary-color-btn {
  background: var(--color-secondary);
  color: #fff;
  &:hover {
    background: var(--color-secondary);
    opacity: 0.7;
  }
}
.primary-btn {
  background: var(--color-secondary);
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  opacity: 1;
  padding: 12px 16px;
  transition: all 0.3s ease;
  i {
    margin-right: 10px;
    display: inline-block;
  }
  &.off {
    opacity: 0.4;
    background: #666666;
    color: #fff;
  }
  &.empty {
    background: none;
    border: 1px solid var(--color-secondary);
    color: var(--color-secondary);
    float: right;
    margin-right: 0;
    margin-left: 10px;
    &:hover {
      background: var(--color-secondary);
      color: #fff !important;
      opacity: 1;
    }
  }
  &.black {
    background: var(--color-primary);
  }
}

.mlr-10 {
  margin: 0 10px 0 10px;
}
.clearfix {
  clear: both;
  display: block;
  height: 0;
  width: 100%;
}

.square-button {
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
  display: block;
  cursor: pointer;
  font-size: 1em;
  padding: 12px;
  width: 150px;
  transition: all 0.3s ease;
  @include hCenter(relative);
  font-family: ""Heebo", sans-serif", sans-serif;
  font-display: swap;
  &:hover {
    background: var(--color-secondary);
    color: #fff;
  }
}

header {
  /* TODO: background */
  background: #fff;
  height: 60px;
  line-height: 50px;
  .fa-plus-circle {
    margin-right: 10px;
  }
  a {
    font-size: 1em;
    margin: 0 20px;
    color: #fff;
    cursor: pointer;
    float: right;
  }
}

.loader {
  height: 100%;
  z-index: 10000000;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 180px);
  background: rgba(0, 0, 0, 0.8);
  svg {
    @include superCenter(absolute);
  }
}

header {
  position: relative;
  #input_container {
    width: 350px;
    height: 30px;
    @include superCenter(absolute);
    margin-left: 20px;
    height: 30px;
    background-color: #f4f4f4;
    color: #282828;
  }
  input {
    height: 20px;
    margin: 0;
    padding-right: 30px;
    width: 100%;
    background-color: #f4f4f4;
    transform: translateY(2px);
  }
  .input_img {
    height: 11.1px;
    color: #bcbcbc !important;
    margin-left: 1rem !important;
  }
  .profile {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(227, 146, 151, 0.39);
    @include superCenter(absolute);
    margin-right: 20px;
  }
  .clientFilter {
    position: relative;
    top: 10px;
    width: 150px;
    z-index: 100;
    height: 30px;
    margin-left: 10px;
    input {
      height: 30px;
    }
  }
  i {
    font-size: 11px;
  }
  .profile-pic {
    position: static !important;
  }
}
.rectangle {
  width: 133.7px;
  height: 30px;
  background-color: var(--color-secondary);
}
.tb-2 {
  transform: translateY(2px);
}
.add-btn {
  font-size: 11px;
  cursor: pointer;
}
.there-is-no-tour {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.23;
  text-align: center;
  color: var(--color-primary);
}
.no-tour-text {
  width: 603px;
  height: 41px;
  opacity: 0.6;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: var(--color-primary);
}
.rectangle-big {
  width: 190px;
  height: 54px;
  background-color: var(--color-secondary);
  font-size: 16px;
}

::-webkit-scrollbar-track {
  background: var(--color-primary);
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: var(--color-secondary);
}
::-webkit-scrollbar {
  width: var(--scroll-bar-size);
  background: var(--color-primary);
}

.ReactModalPortal {
  z-index: 9999;
  position: fixed;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.8) !important;
}
.ReactModal__Content {
  height: 300px;
  width: 450px;
  outline: none !important;
  @include superCenter(absolute);
  .fa-times {
    color: #fff;
    font-size: 2em;
    position: absolute;
    top: -20px;
    right: -20px;
    cursor: pointer;
    background: #000;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    z-index: 10;
    &:hover {
      color: var(--color-secondary);
    }
  }
  .primary-btn {
    width: 100%;
    margin: 10px 0;
    font-size: 1em;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.buttonBg {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  right: 5%;
  top: 40px;
  padding: 20px 20px 20px 10px;
  z-index: 100;
  cursor: pointer;
  transition: all 0.3s ease;
  &.animation {
    animation: scale 3s infinite;
  }
  &.open {
    right: calc(5% + 280px);
  }
  button {
    margin-right: 20px;
  }
  span {
    color: #fff;
    font-size: 1.5em;
    display: inline-block !important;
    margin: 0;
    @media screen and (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 10px 5px 10px 0px;
    top: 20px;
    > span {
      display: none;
    }
  }
}
.appointment-btn-container {
  position: relative;
}

.custom-button {
  max-height: 22px;
  max-width: 30px;
  margin-left: 5px;
}

.appointment-hint {
  position: absolute;
  z-index: 1;
  width: max-content;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px 5px 0;
  border-radius: 20px;
  top: 7px;
  right: 70px;
  white-space: nowrap;

  .fa-times-hint-icon {
    background: var(--color-secondary);
    color: white;
    border-radius: 50%;
    font-size: 10px !important;
    padding: 8px;
    margin: 1px 8px;

    &:hover {
      background: white;
      color: var(--color-secondary);
    }
  }
  span {
    font-size: 12px;
  }
  .fa-arrow-right-hint-icon {
    background: white;
    color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    font-size: 10px !important;
    padding: 3px;
    margin-left: 10px;
  }
}

.no-visible-scrollbar {
  ::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }
}

.cookie-buttons-container,
.cookie-buttons-container-2 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.cookie-buttons-container-2 {
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
}
.cookie-buttons-container {
  @media screen and (max-width: 425px) {
    width: 100%;
    max-width: 360px;
    justify-content: space-between;
  }
}

.cookie-button {
  box-sizing: 'border-box';
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'center';
  align-items: 'center';
  padding: 11.0788px 30.4667px;
  gap: 11.08px;
  width: 'auto';
  height: 48px;
  border-radius: 8.30909px;
  flex: 'none';
  order: 0;
  flex-grow: 0;
  font-size: 16px;

  @media screen and (max-width: 425px) {
    height: 48px;
    width: 100%;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.pl-subtitles-space {
  width: 50%;

  @media screen and (max-width: 900px) {
    margin-right: '20px';
    width: 75%;
  }

  @media screen and (max-width: 600px) {
    margin-right: '20px';
    width: 95%;
  }
}

#intro-screen {
  &>div {
    z-index: 999;
  }
}

#tag-iframe-fullscreen {
  border-radius: 10px
}

.base-tour:not(#ecommerce-tour-root) {
  .path-lock-panel-wrap.mobile {
    //bottom: 80px !important;
    max-width: 80vw !important;
    margin: auto !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    z-index: 999 !important;
    transition: opacity .5s ease;

    &.hide-path {
      opacity: 0 !important;
    }
  }

  .path-lock-panel.mobile {
    border-radius: 20px !important;
  }
}

// to place the tag popup on top of the Navigation search bar (TB-4871)
#global-fullscreen > .tag-popup-container {
  z-index: 9999;
  position: relative;
  width: 100%;
  height: 100dvh;
}
