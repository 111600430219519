.direction-arrow{
  z-index: 100;
  position: fixed;
  opacity: 0;
  transition: opacity .15s ease-in-out;
  background-color: #00A1FC;
  border-radius: 999rem;
  width: 0;
  height: 0;
  box-shadow: 0 0 140px 90px #00A1FC;
  &-image{
    position: relative;
    left: -13px;
    top: -21px;
  }
}
