@import "_vars.scss";

#analytics-root {
  text-align: center;
  background: #f4f4f4;

  .right {
    float: right;
  }

  .left {
    float: left;
  }

  .dayByHourChartContainer input {
    border: 1px solid gray;
  }

  .statistic {
    background: #fff;
    text-align: left;
    width: 32%;
    padding: 30px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;

    h2 {
      font-style: normal;
      font-weight: bold;
      float: left;
      font-size: 28px;
      margin-top: 5px;

      span {
        display: block;
        font-weight: normal;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }

    img {
      float: right;
    }
  }

  .hero {
    padding: 40px;
    width: 100%;
    background: #fff;

    h1 {
      color: #000;
      font-size: 1.4em;
      float: left;
      font-style: normal;
      line-height: 42px;

      span {
        color: var(--color-secondary);
      }
    }

    .dates {
      display: flex;

      & > span {
        padding-top: 7px;
      }
    }

    .dates,
    select {
      float: right;
      border: solid 1px #e8e8e8 !important;

      input {
        color: #000 !important;
        margin: 0 !important;
      }
    }

    select {
      width: 120px;
      margin-top: 0;
      margin-bottom: 0;
      height: 42px;
      margin-left: 15px;
    }

    .react-datepicker-wrapper {
      width: 100px;
      display: inline-block !important;

      input {
        background: none;
        color: #fff;
        text-align: left;

        &:focus {
          outline: 0 !important;
          box-shadow: none !important;
        }
      }
    }
  }

  .buttons {
    margin-bottom: 40px;

    button {
      padding: 10px;
      font-size: 1em;
      cursor: pointer;

      &:focus {
        outline: 0 !important;
        box-shadow: none !important;
      }

      &:nth-child(2) {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }
    }
  }
}

.charts {
  width: 1000px;
  clear: both;
  display: block;
  @include hCenter(relative);
  margin-top: 20px;
  padding-bottom: 30px;
}

.chart-container {
  background: #fff;
  border-radius: 4px;
  margin: 10px 0;
  padding: 20px;
  position: relative;

  .minimap-button-group {
    display: inline-block;
    float: left;
  }

  .react-transform-component {
    margin: auto;
  }

  h4 {
    color: var(--color-primary);
    margin-bottom: 20px;
    font-size: 1.1em;
    text-align: left;
  }

  .floors {
    float: right;

    button {
      float: right;
      border: 1px solid var(--color-secondary);
      margin-left: 10px;
    }
  }

  &.full {
    width: 100%;
    height: auto;
  }

  &.half {
    width: 49%;
    height: auto;
  }

  &.big {
    width: calc(64% + 20px);
    height: auto;
    padding: 58px 20px;

    canvas {
      width: 100% !important;
    }
  }

  &.small {
    width: 32%;
    margin-bottom: 5px;
    height: auto;
    display: inline-block;

    canvas {
      width: 100% !important;
    }

    h4 {
      margin-bottom: 30px;
    }
  }

  .chart {
    @include hCenter(relative);
  }

  .analytics-items-filter {
    min-width: 300px;
    display: inline-block;
  }

  .minimap-heatmap-checkbox-group {
    position: absolute;
    margin-top: 65px;
    width: 100px;
    label {
      display: flex;
      line-height: 20px;
      margin-bottom: 9px;
      input[type=checkbox] {
        width: 20px;
        height: 20px;
        margin: 0 10px 0;
      }
    }
  }

  .minimap {
    @include hCenter(relative);
    height: max-content;
    width: max-content;

    .marker_reworked {
      position: absolute;
      display: block;
      font-size: 0.9em;
      width: 0px;
      height: 0px;
      &:hover {
        .marker_cloud_wrap {
            display: block;
        }
      }

      .marker_cloud_wrap {
        position: absolute;
        width: 100px;
        margin-left: -41px;
        z-index: 4;
        display: none;

        .marker_cloud {
          margin: auto;
          border-radius: 3px;
          padding: 0px 5px;
          background: #FFFFFF;
          font-weight: bold;
          font-size: 12px;
          display: inline-block;

          &.sweep_cloud {
            color: var(--color-secondary);
            position: relative;
          }
        }
        .arrow-down {
          margin:auto;
          width: 0;
          height: 0;
          border-left: 2px solid transparent;
          border-right: 2px solid transparent;

          border-top: 2px solid #ffffff;
        }
      }

      .marker_point {
        border-radius: 50%;
        margin-left: 3px;
        margin-top: 23px;
        z-index: 3;

        &.tag_point {
          background: #FFFFFF;
          box-shadow: 0 0 0 3px var(--color-secondary);
          width: 10px;
          height: 10px;
        }

        &.sweep_point {
          box-shadow: 0 0 0 3px rgba(234, 71, 92, .4);
          width: 12px;
          height: 12px;

          .sweep_inner_point {
            position: absolute;
            margin: 2px;
            border-radius: 50%;
            background: var(--color-secondary);
            box-shadow: 0 0 0 2px rgba(255,255,255,.65);
            width: 8px;
            height: 8px;
          }
        }

      }
    }
  }
}

.conversion-chart {
  height: 100%;
  overflow: hidden;

  .conversion-lines {
    height: 138px;
    display: flex;
    justify-content: space-around;
  }

  .conversion-line {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  
  .conversion-open-line {
    width: 25px;
    height: 100%;
    background: var(--color-secondary);
    position: relative;
    margin-top: 5px;
  }

  .conversion-submit-line {
    width: 100%;
    height: 0%;
    background-color: #60bcff;
    position: absolute;
    bottom: 0px;
  }

  .conversion-labels {
    font-weight: bold;
  }
}
