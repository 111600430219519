.path-mesh-popup{
  &-wrapper{
    display: flex;
    flex-direction: row;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    text-align: center;
    border-radius: 8px;
    padding: 24px;
    color: black;

    width: 400px;

    z-index: -1;
    opacity: 0.5;
    font-family: 'Codec-Pro', sans-serif;
  }

  &-header{
    display: flex;
    font-weight: bold;
    font-size: 20px;
    height: 100%;
    align-items: center;
  }

  &-content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    font-size: 20px;
    max-width: 600px;
  }

  &-description{
    //height: 36px;
    display: block;
    width: 100%;
    padding: 8px  8px;
    position: relative;
    margin: 0 auto;
    line-height: 1.4;
  }

  &-image{
    width: 60px;
    height: 60px;
    margin-right: 60px;
  }

  &-button {
    width: 444px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 68px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 1px solid #2055FF;

    &:focus{
      border: 1px solid #2055FF;
    }

    &--back {
      margin-right: 3px;
      background-color: white;
      color: #2055FF;

    }
    &--next{
      margin-left: 3px;
      background-color: #2055FF;
      color: white;
    }
  }
}
