@import "_vars.scss";

#public-tour-root,
#ecommerce-tour-root {
  .sweep-pop {
    @include superCenter(fixed);
    display: block;
    z-index: 1111111111;
    max-width: 100vw !important;
    max-height: 100vh !important;

    .fa-times {
      top: -10px;
      right: -10px;
    }

    &.text {
      background: rgba(0, 0, 0, 0.5);
      text-align: center;
      color: #fff;
      height: 40px;
      line-height: 40px;
      width: 400px;
      font-size: 16px;
      padding: 0;

      pre {
        white-space: break-spaces;
        text-align: justify;
        color: #fff;
        padding: 1rem;
      }
    }

    &.image {
      height: 500px;
      width: 600px;
      max-width: 100vw;
      max-height: 100vh;
      background: #fff;

      &.s {
        height: 400px;
      }

      .img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: block;
      }
    }

    &.video,
    &.iframe {
      height: 400px;
      width: 600px;

      iframe {
        @include fullpage;
      }

    }

    &.iframe {
      width: 1500px;
      height: 600px;
    }

    &.green-screen {
      background: transparent;

      &.left {
        right: auto;
      }

      &.right {
        left: auto;

        .close {
          left: -10px;
          right: auto;
        }
      }

      video {
        @include fullpage;
        background: transparent;
      }
    }
  }
}
